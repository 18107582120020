import { Component} from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { APIService } from  '../../api.service';
import { MyvariableService } from  '../../constantvariable';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { HttpClient, HttpHeaders} from  '@angular/common/http';
import { ToastrManager } from 'ng6-toastr-notifications';
@Component({
  selector: 'app-dashboard',
  templateUrl: 'login.component.html',
  providers:[MyvariableService]
})

export class LoginComponent{ 
public  result:  any;
public todos : FormGroup;
auth_token: any;
loading = false;
dashboard='tyre-dashboard';
TOAST_TIMEOUT = this.MyvariableService.TOAST_TIMEOUT;
LOADING_HTML = this.MyvariableService.LOADING_HTML;
constructor(private router: Router, private apiService: APIService,public formBuilder: FormBuilder,private MyvariableService: MyvariableService, public toastr: ToastrManager) {  
 this.todos  = this.formBuilder.group({
			username: ['',Validators.required],
			password:['',Validators.required],
		});
		}
		
	onLoggedin() {
		
		var  jsondata  = this.todos.value;
		this.loading = true;
		this.apiService.getApiUrl('login', jsondata).subscribe((data:  any) => {		
		if(data.error=='false'){			
		  this.auth_token = data.body.success.token;
					localStorage.setItem("auth_token", this.auth_token);
					localStorage.setItem("dashboard", data.body.dashboard);
					this.apiService.getToken(this.auth_token);
					this.dashboard = data.body.dashboard;
          this.toastr.successToastr(data.msg, 'Success!', {toastTimeout: this.TOAST_TIMEOUT});		  
		  this.router.navigate([data.body.dashboard]);
		   }else{		
		    this.toastr.errorToastr(data.msg, 'Error!', {toastTimeout: this.TOAST_TIMEOUT});
			 }
			 this.loading = false;
    });
       
	}
	

	forgot(){         
			this.router.navigate(['/forgot-password']);
	  }


	}