import { Injectable } from '@angular/core';
import { AnyMxRecord } from 'dns'; 
@Injectable()

export class MyvariableService {
  firmlogourl : string;
  BASE_API_URL : string;
  VALIDATEHEADING : string;
  PHONE_VALIDATE : any;
  TOAST_TIMEOUT : any;
  BASE_IMAGE_URL:any;
  LOADING_HTML:any;
  LOADING_HTML_POPUP:any;
  ORG_DROPDOWN:string;
  OFFICE_DROPDOWN:any;
  DATE_FORMAT_DD_MM_YYYY:any;
  DATE_FORMAT_DD_MM_YYYY_HH_mm:any;
  DATE_FORMAT_HH_mm:any;
  DATE_FORMAT_MM_YYYY:any;
  SAVED_MSG:any;
  SAVED_MSG_FAILED:any;
  STATUS_MSG:any;
  STATUS_MSG_FAILED:any;
  REJECT_MSG_FAILED:any;
  REJECT_MSG:any;
  base_image_url:any;
  NOT_FOUND:any;
  Inspection_Image_Url:any;
  FILE_UPLOAD_URL:any;
  FILE_UPLOAD_URL_FILE:any;
  BASE_FULL_API_URL:any;
  BASE_SERVER_API_URL:any;
  FLEECA_DOT_IN_URL:any;
  page_not_found:any;
  google_map_icon:any;
  S3_URL:any;
  MULTISELECT_SETTINGS = {};
  constructor() {

//Live Api Url

 this.base_image_url = 'https://lms.fleeca.in/fleecatesting/assets';
 this.firmlogourl = 'https://lms.fleeca.in/fleecatesting/assets/upload';
 this.VALIDATEHEADING = 'This field is required';
 this.BASE_API_URL =  'https://lms.fleeca.in/';
 this.BASE_SERVER_API_URL =  'https://lms.fleeca.in/fleecatesting';
 this.BASE_FULL_API_URL =  'https://lms.fleeca.in/dist/#';
 this.Inspection_Image_Url = 'https://lms.fleeca.in/fleecatesting/assets';
 this.BASE_IMAGE_URL = 'https://lms.fleeca.in/fleecatesting/assets'; 
 this.FILE_UPLOAD_URL = 'https://app.fleeca.in/fleecatest/api/image/upload/';
 this.FILE_UPLOAD_URL_FILE = 'https://app.fleeca.in/fleecatest/assets/';

//Demo Api Url
/*
 this.base_image_url = 'http://demo.fleeca.in/fleecatesting/assets';
 this.firmlogourl = 'http://demo.fleeca.in/fleecatesting/assets/upload';
 this.VALIDATEHEADING = 'This field is required';
 this.BASE_API_URL =  'http://demo.fleeca.in/';
 this.BASE_SERVER_API_URL =  'http://demo.fleeca.in/fleecatesting';
 this.BASE_FULL_API_URL =  'https://app.fleeca.in/dist/#';
 this.Inspection_Image_Url = 'http://demo.fleeca.in/fleecatesting/assets';
 this.BASE_IMAGE_URL = 'http://demo.fleeca.in/fleecatesting/assets'; 
 this.FILE_UPLOAD_URL = 'http://demo.fleeca.in/fleecatesting/api/image/upload/';
 this.FILE_UPLOAD_URL_FILE = 'http://demo.fleeca.in/fleecatesting/assets/';
*/

 this.FLEECA_DOT_IN_URL = 'https://www.fleeca.in/';
 this.S3_URL = "https://s3.ap-south-1.amazonaws.com/s3webaccess";
//this.FILE_UPLOAD_URL = 'http://192.168.0.225/fleeca/api/image/upload/';
//this.FILE_UPLOAD_URL_FILE = 'http://192.168.0.225/fleeca/assets/';

this.page_not_found = '404';

 this.PHONE_VALIDATE = [/[1-9]/, /\d/, /\d/,/\d/, /\d/, /\d/,/\d/, /\d/, /\d/, /\d/];
 this.TOAST_TIMEOUT =  5000;
 this.LOADING_HTML = '<div class="sk-three-bounce"><div class="sk-child sk-bounce1"></div><div class="sk-child sk-bounce2"></div><div class="sk-child sk-bounce3"></div></div>';
 this.LOADING_HTML_POPUP = '<div class="sk-three-bounce"><div class="sk-child sk-bounce1"></div><div class="sk-child sk-bounce2"></div><div class="sk-child sk-bounce3"></div></div>';
 this.ORG_DROPDOWN = '<ng-select formControlName="orgId" (ngModelChange)="org_basis_offices($event)" class="bootstrap" placeholder="Select Organization" [options]="organizations" [allowClear]="true" style="width:150px;"></ng-select>';
 this.OFFICE_DROPDOWN = '<ng-select formControlName="officeId" class="bootstrap" placeholder="Select Office" [options]="offices" [allowClear]="true" style="width:150px;"></ng-select>';
 this.google_map_icon = ' <i class="fa fa-map-marker mapicon"></i> ';
 this.DATE_FORMAT_DD_MM_YYYY = 'dd MMM yyyy';
 this.DATE_FORMAT_MM_YYYY = 'MMM yyyy';
 this.DATE_FORMAT_DD_MM_YYYY_HH_mm = 'dd MMM yyyy HH:mm';
 this.DATE_FORMAT_HH_mm = 'HH:mm';
 this.SAVED_MSG = "Saved Successfully!";
 this.SAVED_MSG_FAILED = "There are some problems occured.";
 this.STATUS_MSG = "Status changed successfully!";
 this.STATUS_MSG_FAILED = "Status not changed due to some error.";
 this.REJECT_MSG = "Rejected successfully!";
 this.REJECT_MSG_FAILED = "There are some errors occured.";
 this.NOT_FOUND = "Data not found."
 this.MULTISELECT_SETTINGS = {
  singleSelection: false,
  idField: 'value',
  textField: 'label',
  enableCheckAll: true,
  selectAllText: 'Select All',
  unSelectAllText: 'Unselect All',
  allowSearchFilter: true,
  limitSelection: -1,
  clearSearchFilter: true,
  maxHeight: 197,
  itemsShowLimit: 3,
  searchPlaceholderText: 'Search',
  noDataAvailablePlaceholderText: 'No Data Available',
  closeDropDownOnSelection: false,
  showSelectedItemsAtTop: false,
  defaultOpen: false
  }; 
  }

}

