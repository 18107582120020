import { Component, OnInit, Input, Injectable,ViewEncapsulation } from '@angular/core';
import { Router, NavigationExtras, NavigationEnd, ActivatedRoute } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { navItems } from './../../_nav';
import { APIService } from  '../../api.service';
import { MyvariableService } from  '../../constantvariable';
//import { Headers } from '@angular/http';
import { HttpClient, HttpHeaders} from  '@angular/common/http';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  trigger,
  state,
  style,
  animate,
  transition
} from '@angular/animations';
@NgModule({
  imports: [
    BrowserModule,
    CommonModule    
  ],
  exports: [
    BrowserModule,
    CommonModule
  ]
})
@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html',
   providers:[MyvariableService],
   encapsulation: ViewEncapsulation.None,
   animations: [
    trigger('collapse', [
      state('open', style({
        opacity: '1',
        display: 'block',
        transform: 'translate3d(0, 0, 0)'
      })),
      state('closed',   style({
        opacity: '0',
        display: 'none',
        transform: 'translate3d(0, -100%, 0)'
      })),
      transition('closed => open', animate('200ms ease-in')),
      transition('open => closed', animate('100ms ease-out'))
    ])
  ]
})
export class DefaultLayoutComponent {
  public navItems = navItems;
  public sidebarMinimized = true;
  private changes: MutationObserver;
  public element: HTMLElement = document.body;
  public userdetail: any;
  public modules: any;
  public submodules: any;
  auth_token: any;
  navSubItems:any;
  loading = false;
  firmlogourl= this.MyvariableService.firmlogourl;
  BASE_API_URL= this.MyvariableService.BASE_API_URL;
  LOADING_HTML = this.MyvariableService.LOADING_HTML;
  FILE_UPLOAD_URL_FILE = this.MyvariableService.FILE_UPLOAD_URL_FILE;
  name:any;
  shortname='';
  firmname='';
  logo:any;
  userimage:any;
  firmlogofullurl=this.FILE_UPLOAD_URL_FILE+'/logo.png';
  dashboard:any;
  public token:  any;
  tpms_notifications_count:any;
  tpms_notifications:any;orgs:any;
  constructor(private router: Router, private http: HttpClient, private apiService: APIService, private MyvariableService: MyvariableService) {
    this.dashboard = localStorage.getItem("dashboard");
    this.changes = new MutationObserver((mutations) => {
      this.sidebarMinimized = document.body.classList.contains('sidebar-minimized');
      
    });

    this.changes.observe(<Element>this.element, {
      attributes: true
    });
    this.token = localStorage.getItem("auth_token");
    /* if(this.token==undefined){
      this.router.navigate(['login']);
    } */

  }
  
  
  logout() {
			var  jsondata  = JSON.stringify('');
			this.apiService.getApiUrl('logout', jsondata).subscribe((data:  any) => {
				localStorage.setItem('auth_token',null);
				//alert(data);
          //localStorage.removeItem('auth_token');
        localStorage.clear();
				this.router.navigate(['login']);
			});
		}
	
  
  	ngOnInit():void {
	
      this.loading = true;
		var jsondata  = JSON.stringify('');        
		this.apiService.getApiUrl('base', jsondata).subscribe((data:  any) => {
      this.userdetail  =  data.userdetail;
      
      if(this.userdetail.length>0){
        this.orgs = this.userdetail.organizations;
        this.shortname=this.userdetail.organizations.shortname;
        this.firmname=this.userdetail.organizations.firmName;
        this.logo=this.userdetail.organizations.logo;
        this.firmlogofullurl = this.firmlogourl+'/'+this.logo;
      }
      
      this.tpms_notifications=data.tpms_notifications;
      this.tpms_notifications_count = data.tpms_notifications_count;
      
      this.navItems = []; 
    this.navSubItems = [];
    /* this.navItems.push(
      {
        name: 'Dashboard',
        url: '/dashboard',
        icon: 'icon-speedometer',
       
      }
    ); */
    
    
		this.modules  =  data.modules;
		this.submodules  =  data.submodules;
		this.name=this.userdetail.name;
		
    this.userimage=this.userdetail.image;     
		
		for(var i=0;i<this.modules.length;i++ ){			
			
			for(var j=0;j<this.submodules.length;j++ ){
				
				if(this.modules[i].mid==this.submodules[j].mid){
				this.navSubItems.push({
					
					name: this.submodules[j].subModuleName,
					url:  '/'+this.submodules[j].pageUrl,
          icon: 'icon-arrow-right'
		
				})
			}
			}			
			
		this.navItems.push(
  {
    name: this.modules[i].moduleName,
    url: this.modules[i].pageUrl,
    icon: this.modules[i].moduleIcon,
    children: 
      this.navSubItems
	  
  },
  
  
)
this.navSubItems = [];
		}


  });

  this.loading = false;


//  var elms = document.getElementsByClassName("nav-item");
//   while (elms.length > 0) {
//    elms[0].classList.remove('open');
//   }

    }
        

}