import { Component, TemplateRef,ViewChild} from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { APIService } from  '../../../api.service';
import { MyvariableService } from  '../../../constantvariable';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { HttpClient, HttpHeaders} from  '@angular/common/http';
import { ToastrManager } from 'ng6-toastr-notifications';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
@Component({
  selector: 'app-dashboard',
  templateUrl: 'forgot-password.component.html',
  providers:[MyvariableService]
})

export class ForgotpasswordComponent{ 
public  result:  any;
public todos : FormGroup;
public todos1 : FormGroup;
loading = false;
dashboard='tyre-dashboard';
TOAST_TIMEOUT = this.MyvariableService.TOAST_TIMEOUT;
LOADING_HTML = this.MyvariableService.LOADING_HTML;
userid: any=0;
otp_insert = false;
modalRef: BsModalRef;
sendotp='Send OTP';
config = {
    backdrop: true,
    ignoreBackdropClick: true
  };
  abc:any;
message: string;
email:string;
mobile:string;
@ViewChild('template', {  }) modalTemplate : TemplateRef<any>;
	inputt: boolean;
constructor(private router: Router, private apiService: APIService,public formBuilder: FormBuilder,private MyvariableService: MyvariableService, public toastr: ToastrManager,private modalService: BsModalService) {
	this.createForm();		  
	this.createForm1(this.userid);
}



	sendOtp(){
		
		var jsondata = this.todos.value;
		this.loading = true;
		this.apiService.getApiUrl('forgot-pass',jsondata).subscribe((data: any)=>{
			if(data.error=='false'){
				this.userid=data.userid;
				this.toastr.successToastr(data.msg, 'Success!', {toastTimeout: this.TOAST_TIMEOUT});
				this.createForm1(this.userid);
				this.otp_insert=true;
				this.inputt=false;
				this.sendotp='Resend OTP';
				 }else{		
				  this.toastr.errorToastr(data.msg, 'Error!', {toastTimeout: this.TOAST_TIMEOUT});
				   }
				   this.loading = false
				   

		});
	
	}
	UpdatePass(){
		var jsondata = this.todos1.value;
		this.loading = true;
		this.apiService.getApiUrl('update-pass',jsondata).subscribe((data: any)=>{
			if(data.error=='false'){
				
				this.toastr.successToastr(data.msg, 'Success!', {toastTimeout: this.TOAST_TIMEOUT});
				this.router.navigate(['/login']);
				
				 }else{		
				  this.toastr.errorToastr(data.msg, 'Error!', {toastTimeout: this.TOAST_TIMEOUT});
				   }
				   this.loading = false;

		});

	}

	createForm(){
		this.todos  = this.formBuilder.group({
		  email: [''],
		  mobile: ['']
	   });
	   }
	   createForm1(userid){
		this.todos1  = this.formBuilder.group({
			userid:[userid],
			otp: ['',Validators.required],
			password: ['',Validators.required]
		 });
	   }

	   openModal(template: TemplateRef<any>) {
		this.modalRef = this.modalService.show(template, this.config);
		
	  }
	  confirm(): void {
		this.modalRef.hide();
		this.router.navigate(['/login']);
	  }

	  backtologin() {
		this.router.navigate(['/login']);
	  }


	  onKey(mobile:any){
		var email= this.todos.controls['email'].value;
		if(mobile==''||mobile==null){
			if(email==''||email==null){
				this.abc=false;
			  }
			  else{
			this.abc=true;
			}
		  }
		  else{
			  this.abc=true;
		  }
	  }

	  onKey2(email:any){
		var mobile = this.todos.controls['mobile'].value;
		if(email==''||email==null){
		  if(mobile==''||mobile==null){
				this.abc=false;
			  }
			  else{
			this.abc=true;
			}
		  }
		  else{
			  this.abc=true;
		  }
	  }
	}