import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
// Import Containers
import { DefaultLayoutComponent } from './containers';
//import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { LoginComponent } from './views/login/login.component';
import { RegisterComponent } from './views/register/register.component';
import { ForgotpasswordComponent } from './views/login/forgot-password/forgot-password.component';

export const routes: Routes = [
{
path: '',
redirectTo: 'login',
pathMatch: 'full',
},
{
path: 'logout',
redirectTo: 'login'
},  
{
path: '500',
component: P500Component,
data: {
title: 'Page 500'
}
},
{
path: 'forgot-password',
component: ForgotpasswordComponent,
data: {
title: 'Forgott password Page'
}
},
{
path: 'login',
component: LoginComponent,
data: {
title: 'Login Page'
}
},
{
path: 'register',
component: RegisterComponent,
data: {
title: 'Register Page'
}
},
{
path: '',
component: DefaultLayoutComponent,
data: {
title: 'Home'
},
children: [
{
path: '404',
loadChildren: './views/error/404.module#P404Module'  
},
{
path: 'base',
loadChildren: './views/base/base.module#BaseModule'
},
{
path: 'dashboard',
loadChildren: './views/dashboard/dashboard.module#DashboardModule'
},
{
path: 'masters',
loadChildren: './views/master/master.module#MasterModule'
},
{
path: 'masters/create',
loadChildren: './views/master/create/master-create.module#MasterCreateModule'
},
{
path: 'masters/edit',
loadChildren: './views/master/edit/master-edit.module#MasterEditModule'
},
{
    path: 'tyre-status-master',
    loadChildren: './views/tyre-status-master/tyre-status-master.module#TyrestatusmasterModule'
    },
    {
    path: 'tyre-status-master/create',
    loadChildren: './views/tyre-status-master/create/tyre-status-master-create.module#TyrestatusmasterCreateModule'
    },
{
path: 'profile',
loadChildren: './views/profile/profile.module#ProfileModule'
},
{
path: 'profile/edit',
loadChildren: './views/profile/edit/profile-edit.module#ProfileEditModule'
},
{
path: 'org-master',
loadChildren: './views/org-master/org-master.module#OrgMasterModule'
},
{
path: 'office/create',
loadChildren: './views/office/create/office.module#OfficeModule'
},
{
path: 'office',
loadChildren: './views/office/manage-locationmaster.module#ManageLocationmasterModule'
},
{
path: 'office/view',
loadChildren: './views/office/view/office-view.module#OfficeViewModule'
},
{
path: 'office/edit',
loadChildren: './views/office/edit/edit.module#EditModule'
},

  {
path: 'organizations',
loadChildren: './views/organizations/organizations.module#OrganizationsModule'
},
{
path: 'organizations/create',
loadChildren: './views/organizations/create/organizations-create.module#OrganizationsCreateModule'
},
{
path: 'organizations/edit',
loadChildren: './views/organizations/edit/organizations-edit.module#OrganizationsEditModule'
},
{
path: 'organizations/view',
loadChildren: './views/organizations/view/organizations-view.module#OrganizationsViewModule'
},
{
path: 'users',
loadChildren: './views/users/users.module#UsersModule'
},
{
path: 'users/create',
loadChildren: './views/users/create/users-create.module#UsersCreateModule'
},
{
path: 'users/edit',
loadChildren: './views/users/edit/users-edit.module#UsersEditModule'
},
{
path: 'users/view',
loadChildren: './views/users/view/users-view.module#UsersViewModule'
},
{
path: 'vehicles',
loadChildren: './views/vehicles/vehicles.module#VehiclesModule',
data: {
title: 'Some Page'
}
},
{
path: 'vehicles/create',
loadChildren: './views/vehicles/create/vehicles-create.module#VehiclesCreateModule'
},
{
path: 'vehicles/edit',
loadChildren: './views/vehicles/edit/vehicles-edit.module#VehiclesEditModule'
},
{
path: 'vehicles/view',
loadChildren: './views/vehicles/view/vehicles-view.module#VehiclesViewModule'
},
{
path: 'vehicle/fitment',
loadChildren: './views/vehicles/fitment/vehicles-fitment.module#VehiclesFitmentModule'
},
{
path: 'inspections',
loadChildren: './views/inspections/inspections.module#InspectionsModule'
},
{
path: 'inspections/inspection-confirm',
loadChildren: './views/inspections/confirm/inspection-confirm.module#InspectionConfirmModule'
},
{
path: 'fleeca-center',
loadChildren: './views/fleeca-center/fleecacenter.module#FleecacenterModule'
},
{
path: 'fleeca-center/create',
loadChildren: './views/fleeca-center/create/fleecacenter-create.module#FleecacenterCreateModule'
},
{
path: 'fleeca-center/edit',
loadChildren: './views/fleeca-center/edit/fleecacenter-edit.module#FleecacenterEditModule'
},
{
path: 'fleeca-center/view',
loadChildren: './views/fleeca-center/view/fleecacenter-view.module#FleecacenterViewModule'
},
{
path: 'channel-partner',
loadChildren: './views/channel-partner/channel-partner.module#ChannelPartnerModule'
},
{
path: 'channel-partner/create',
loadChildren: './views/channel-partner/create/channel-partner-create.module#ChannelPartnerCreateModule'
},
{
path: 'channel-partner/edit',
loadChildren: './views/channel-partner/edit/channel-partner-edit.module#ChannelPartnerEditModule'
},
{
path: 'batch-start',
loadChildren: './views/batch-start/batch-start.module#BatchstartModule'
},
{
path: 'batch-start/create',
loadChildren: './views/batch-start/create/batch-start-create.module#BatchstartCreateModule'
},
{
path: 'batch-start/edit',
loadChildren: './views/batch-start/edit/batch-start-edit.module#BatchstartEditModule'
},
{
path: 'batch-start/view',
loadChildren: './views/batch-start/view/batch-start-view.module#BatchstartViewModule'
},
{
path: 'vehicle-allignment',
loadChildren: './views/vehicle-allignment/vehicle-allignment.module#VehicleallignmentModule'
},
{
path: 'vehicle-allignment/create',
loadChildren: './views/vehicle-allignment/create/vehicle-allignment-create.module#VehicleallignmentCreateModule'
},
{
path: 'vehicle-allignment/edit',
loadChildren: './views/vehicle-allignment/edit/vehicle-allignment-edit.module#VehicleallignmentEditModule'
},
{
path: 'vehicle-allignment/view',
loadChildren: './views/vehicle-allignment/view/vehicle-allignment-view.module#VehicleallignmentViewModule'
},
{
path: 'tyre-update',
loadChildren: './views/tyre-update/tyre-update.module#TyreupdateModule'
},
{
path: 'tyre-update/create',
loadChildren: './views/tyre-update/create/tyre-update-create.module#TyreupdateCreateModule'
},
{
path: 'tyre-update/edittyre',
loadChildren: './views/tyre-update/edittyre/tyre-update-edittyre.module#TyreupdateEdittyreModule'
},
{
path: 'tyre-update/editkm',
loadChildren: './views/tyre-update/editkm/tyre-update-editkm.module#TyreupdateEditkmModule'
},
{
path: 'tyre-update/editstatus',
loadChildren: './views/tyre-update/editstatus/tyre-update-editstatus.module#TyreupdateEditstatusModule'
},
{
path: 'tyre-update/view',
loadChildren: './views/tyre-update/view/tyre-update-view.module#TyreupdateViewModule'
},
{
path: 'tyre-update/stock-transfer',
loadChildren: './views/tyre-update/stock-transfer/stock-transfer.module#StockTransferModule'
},
{
path: 'lead-creation',
loadChildren: './views/lead-creation/lead-creation.module#LeadcreationModule'
},
{
path: 'lead-creation/create',
loadChildren: './views/lead-creation/create/lead-creation-create.module#LeadcreationCreateModule'
},
{
path: 'lead-creation/edit',
loadChildren: './views/lead-creation/edit/lead-creation-edit.module#LeadcreationEditModule'
},
{
path: 'lead-creation/view',
loadChildren: './views/lead-creation/view/lead-creation-view.module#LeadcreationViewModule'
},
{
path: 'trailer-master',
loadChildren: './views/trailer-master/trailer-master.module#TrailermasterModule'
},
{
path: 'trailer-master/create',
loadChildren: './views/trailer-master/create/trailer-master-create.module#TrailermasterCreateModule'
},
{
path: 'trailer-mapping',
loadChildren: './views/trailer-mapping/trailer-mapping.module#TrailermappingModule'
},
{
path: 'trailer-mapping/create',
loadChildren: './views/trailer-mapping/create/trailer-mapping-create.module#TrailermappingCreateModule'
},
{
path: 'customer-list',
loadChildren: './views/customer-list/customer-list.module#CustomerlistModule'
},
{
path: 'customer-list/edit',
loadChildren: './views/customer-list/edit/customer-list-edit.module#CustomerlistEditModule'
},
{
path: 'customer-list/view',
loadChildren: './views/customer-list/view/customer-list-view.module#CustomerlistViewModule'
},
{
path: 'tyre-item-master',
loadChildren: './views/tyre-item-master/tyre-item-master.module#TyreitemmasterModule'
},
{
path: 'tyre-item-master/create',
loadChildren: './views/tyre-item-master/create/tyre-item-master-create.module#TyreitemmasterCreateModule'
},
{
path: 'tyre-item-master/edit',
loadChildren: './views/tyre-item-master/edit/tyre-item-master-edit.module#TyreitemmasterEditModule'
},
{
path: 'tyre-item-master/view',
loadChildren: './views/tyre-item-master/view/tyre-item-master-view.module#TyreitemmasterViewModule'
},
{
path: 'make',
loadChildren: './views/make/make.module#MakeModule'
},
{
path: 'make/create',
loadChildren: './views/make/create/make-create.module#MakeCreateModule'
},
{
path: 'make/edit',
loadChildren: './views/make/edit/make-edit.module#MakeEditModule'
},
{
  path: 'make-master',
  loadChildren: './views/make-master/make-master.module#MakeMasterModule'
  },
  {
  path: 'make-master/create',
  loadChildren: './views/make-master/create/make-master-create.module#MakeMasterCreateModule'
  },
  {
  path: 'make-master/edit',
  loadChildren: './views/make-master/edit/make-master-edit.module#MakeMasterEditModule'
  },
{
path: 'customer-query',
loadChildren: './views/customer-query/customer-query.module#CustomerqueryModule'
},  
{
path: 'customer-query/edit',
loadChildren: './views/customer-query/edit/customer-query-edit.module#CustomerqueryEditModule'
},
{
path: 'customer-query/view',
loadChildren: './views/customer-query/view/customer-query-view.module#CustomerqueryViewModule'
},
{
path: 'sitewise-inspection',
loadChildren: './views/sitewise-inspection/sitewise-inspection.module#SitewiseinspectionModule'
},
{
path: 'executive-wise-inspection',
loadChildren: './views/executive-wise-inspection/executive-wise-inspection.module#ExecutivewiseinspectionModule'
},
{
path: 'inspection-time-report',
loadChildren: './views/inspection-time-report/inspection-time-report.module#InspectiontimereportModule'
},
{
path: 'tyre-not-found-report',
loadChildren: './views/tyre-not-found-report/tyre-not-found-report.module#TyrenotfoundreportModule'
},
{
path: 'serv-engg-daily-report',
loadChildren: './views/serv-engg-daily-report/serv-engg-daily-report.module#ServenggdailyreportModule'
},
{
path: 'login-history',
loadChildren: './views/login-history/login-history.module#LoginhistoryModule'
},
{
path: 'executive-report',
loadChildren: './views/executive-report/executive-report.module#ExecutivereportModule'
},
{
path: 'batch-report',
loadChildren: './views/batch-report/batch-report.module#BatchreportModule'
},
{
path: 'pdf-report',
loadChildren: './views/pdf-report/pdf-report.module#PdfreportModule'
},
{
path: 'sales-report',
loadChildren: './views/sales-report/sales-report.module#SalesreportModule'
},
{
path: 'inspection-report-issue',
loadChildren: './views/inspection-report-issue/inspection-report-issue.module#InspectionreportissueModule'
},
{
path: 'inspection-report',
loadChildren: './views/inspection-report/inspection-report.module#InspectionreportModule'
},
{
path: 'inspection-report/edit',
loadChildren: './views/inspection-report/edit/inspection-report-edit.module#InspectionReportEditModule'
},
{
path: 'inspection-report/view',
loadChildren: './views/inspection-report/view/inspection-report-view.module#InspectionreportViewModule'
},
{
path: 'inspection-report/tyre-view',
loadChildren: './views/inspection-report/tyre-view/inspection-report-tyre-view.module#InspectionreportTyreViewModule'
},
{
path: 'vehicle-dashboard',
loadChildren: './views/vehicle-dashboard/vehicle-dashboard.module#VehicledashboardModule'
},
{
path: 'diesel-dashboard',
loadChildren: './views/diesel-dashboard/diesel-dashboard.module#DieseldashboardModule'
},
{
path: 'tyre-dashboard',
loadChildren: './views/tyre-dashboard/tyre-dashboard.module#TyredashboardModule'
},
{
path: 'tpms-dashboard',
loadChildren: './views/tpms-dashboard/tpms-dashboard.module#TpmsdashboardModule'
},
{
path: 'tpms',
loadChildren: './views/tpms/tpms.module#TpmsModule'
},
{
  path: 'finance-dashboard',
  loadChildren: './views/finance-dashboard/finance-dashboard.module#FinanceDashboardModule'
  },
{
path: 'fleeca-center-revenue-dashboard',
loadChildren: './views/fleeca-center-revenue-dashboard/fleeca-center-revenue-dashboard.module#FleecaCenterRevenueDashboardModule'
},
{
path: 'otp-analysis-dashboard',
loadChildren: './views/otp-analysis-dashboard/otp-analysis-dashboard.module#OTPAnalysisDashboardModule'
},
{
path: 'client-analysis-report-bi',
loadChildren: './views/client-analysis-report-bi/client-analysis-report-bi.module#ClientanalysisreportbiModule'
},
{
path: 'cpkm-report-powerbi',
loadChildren: './views/cpkm-report-powerbi/cpkm-report-powerbi.module#CPKMReportPowerbiModule'
},
{
path: 'gps-dashboard',
loadChildren: './views/gps-dashboard/gps-dashboard.module#GPSDashboardModule'
},
{
path: 'fleeca-dashboard',
loadChildren: './views/fleeca-dashboard/fleeca-dashboard.module#FleecadashboardModule'
},
{
path: 'model',
loadChildren: './views/model/model.module#ModelModule'
},
{
path: 'model/create',
loadChildren: './views/model/create/model-create.module#ModelCreateModule'
},
{
path: 'model/edit',
loadChildren: './views/model/edit/model-edit.module#ModelEditModule'
},
{
path: 'model/view',
loadChildren: './views/model/view/model-view.module#ModelViewModule'
},
{
path: 'tpms-model',
loadChildren: './views/tpms-model/tpms-model.module#TPMSModelModule'
},
{
path: 'tpms-model/create',
loadChildren: './views/tpms-model/create/tpms-model-create.module#TPMSModelCreateModule'
},
{
path: 'tpms-model/edit',
loadChildren: './views/tpms-model/edit/tpms-model-edit.module#TPMSModelEditModule'
},
{
path: 'tpms-model/view',
loadChildren: './views/tpms-model/view/tpms-model-view.module#TPMSModelViewModule'
},
{
path: 'partydetail',
loadChildren: './views/partydetail/partydetail.module#PartydetailModule'
},
{
path: 'partydetail/create',
loadChildren: './views/partydetail/create/partydetail-create.module#PartydetailCreateModule'
},
{
path: 'partydetail/edit',
loadChildren: './views/partydetail/edit/partydetail-edit.module#PartydetailEditModule'
},
{
path: 'partydetail/view',
loadChildren: './views/partydetail/view/partydetail-view.module#PartydetailViewModule'
},
{
path: 'vendordetail',
loadChildren: './views/vendordetail/vendordetail.module#VendordetailModule'
},
{
path: 'vendordetail/create',
loadChildren: './views/vendordetail/create/vendordetail-create.module#VendordetailCreateModule'
},
{
path: 'vendordetail/edit',
loadChildren: './views/vendordetail/edit/vendordetail-edit.module#VendordetailEditModule'
},
{
path: 'vendordetail/view',
loadChildren: './views/vendordetail/view/vendordetail-view.module#VendordetailViewModule'
},
{
path: 'mail-config',
loadChildren: './views/mail-config/mail-config.module#MailconfigModule'
},
{
path: 'mail-config/create',
loadChildren: './views/mail-config/create/mail-config-create.module#MailconfigCreateModule'
},
{
path: 'mail-config/edit',
loadChildren: './views/mail-config/edit/mail-config-edit.module#MailconfigEditModule'
},  
{
path: 'master-type',
loadChildren: './views/master-type/master-type.module#MastertypeModule'
},
{
path: 'master-type/create',
loadChildren: './views/master-type/create/master-type-create.module#MastertypeCreateModule'
},
{
path: 'master-type/edit',
loadChildren: './views/master-type/edit/master-type-edit.module#MastertypeEditModule'
}, 
{
path: 'cpkm-calculator',
loadChildren: './views/cpkm-calculator/cpkm-calculator.module#CpkmcalculatorModule'
},
{
path: 'cpkm-calculator/create',
loadChildren: './views/cpkm-calculator/create/cpkm-calculator-create.module#CpkmcalculatorCreateModule'
},
{
path: 'cpkm-calculator/edit',
loadChildren: './views/cpkm-calculator/edit/cpkm-calculator-edit.module#CpkmcalculatorEditModule'
},
{
path: 'cpkm-calculator/view',
loadChildren: './views/cpkm-calculator/view/cpkm-calculator-view.module#CpkmcalculatorViewModule'
},  
{
path: 'employee',
loadChildren: './views/employee/employee.module#EmployeeModule'
},
{
path: 'employee/create',
loadChildren: './views/employee/create/employee-create.module#EmployeeCreateModule'
},
{
path: 'employee/edit',
loadChildren: './views/employee/edit/employee-edit.module#EmployeeEditModule'
},
{
path: 'employee/view',
loadChildren: './views/employee/view/employee-view.module#EmployeeViewModule'
},
{
  path: 'employee/generate-salary',
  loadChildren: './views/employee/generate-salary/generate-salary.module#EmployeeGeneratesalaryModule'
},
{
  path: 'worklist',
  loadChildren: './views/worklist/worklist.module#WorklistModule'
},
{
path: 'tyre-km',
loadChildren: './views/tyre-km/tyre-km.module#TyrekmModule'
}, 
{
path: 'material-dispatch',
loadChildren: './views/material-dispatch/material-dispatch.module#MaterialdispatchModule'
},
{
path: 'material-dispatch/create',
loadChildren: './views/material-dispatch/create/material-dispatch-create.module#MaterialdispatchCreateModule'
},
{
path: 'material-dispatch/edit',
loadChildren: './views/material-dispatch/edit/material-dispatch-edit.module#MaterialdispatchEditModule'
},
{
path: 'material-dispatch/view',
loadChildren: './views/material-dispatch/view/material-dispatch-view.module#MaterialdispatchViewModule'
}, 
{
path: 'material-dispatch-sale',
loadChildren: './views/material-dispatch-sale/material-dispatch-sale.module#MaterialdispatchsaleModule'
},
{
path: 'material-dispatch-sale/create',
loadChildren: './views/material-dispatch-sale/create/material-dispatch-sale-create.module#MaterialdispatchsaleCreateModule'
},
{
  path: 'stock-reconcillation',
  loadChildren: './views/stock-reconcillation/stock-reconcillation.module#StockReconcillationModule'
  },
{
path: 'module',
loadChildren: './views/module/module.module#ModuleModule'
},
{
path: 'module/create',
loadChildren: './views/module/create/module-create.module#ModuleCreateModule'
},
{
path: 'module/edit',
loadChildren: './views/module/edit/module-edit.module#ModuleEditModule'
},  
{
path: 'sub-module',
loadChildren: './views/sub-module/sub-module.module#SubmoduleModule'
},
{
path: 'sub-module/create',
loadChildren: './views/sub-module/create/sub-module-create.module#SubmoduleCreateModule'
},
{
path: 'sub-module/edit',
loadChildren: './views/sub-module/edit/sub-module-edit.module#SubmoduleEditModule'
},  
{
path: 'task-master',
loadChildren: './views/task-master/task-master.module#TaskmasterModule'
},
{
path: 'task-master/create',
loadChildren: './views/task-master/create/task-master-create.module#TaskmasterCreateModule'
},
{
path: 'task-master/edit',
loadChildren: './views/task-master/edit/task-master-edit.module#TaskmasterEditModule'
},  
{
path: 'task-level',
loadChildren: './views/task-level/task-level.module#TasklevelModule'
},
{
path: 'task-level/create',
loadChildren: './views/task-level/create/task-level-create.module#TasklevelCreateModule'
},
{
path: 'task-level/edit',
loadChildren: './views/task-level/edit/task-level-edit.module#TasklevelEditModule'
}, 
{
path: 'approval',
loadChildren: './views/approval/approval.module#ApprovalModule'
},

{
path: 'approval-user',
loadChildren: './views/approval-user/approval-user.module#ApprovaluserModule'
},
{
path: 'tyre-tag-update',
loadChildren: './views/tyre-tag-update/tyre-tag-update.module#TyretagupdateModule'
},
{
path: 'tyre-tag-update/create',
loadChildren: './views/tyre-tag-update/create/tyre-tag-update-create.module#TyretagupdateCreateModule'
},
{
path: 'retread-tyre-updation',
loadChildren: './views/retread-tyre-updation/retread-tyre-updation.module#RetreadtyreupdationModule'
},
{
path: 'retread-tyre-updation/edit',
loadChildren: './views/retread-tyre-updation/edit/retread-tyre-updation-edit.module#RetreadtyreupdationEditModule'
},
{
path: 'received-retread-tyre',
loadChildren: './views/received-retread-tyre/received-retread-tyre.module#ReceivedretreadtyreModule'
},
{
path: 'received-retread-tyre/create',
loadChildren: './views/received-retread-tyre/create/received-retread-tyre-create.module#ReceivedretreadtyreCreateModule'
},
{
path: 'received-retread-tyre/print',
loadChildren: './views/received-retread-tyre/print/received-retread-tyre-print.module#ReceivedretreadtyrePrintModule'
},
{
path: 'received-retread-tyre/upload-excel',
loadChildren: './views/received-retread-tyre/upload-excel/received-retread-tyre-upload-excel.module#ReceivedretreadtyreUploadexcelModule'
},
{
path: 'tyre-retread',
loadChildren: './views/tyre-retread/tyre-retread.module#TyreretreadModule'
},
{
path: 'tyre-retread/create',
loadChildren: './views/tyre-retread/create/tyre-retread-create.module#TyreretreadCreateModule'
},
{
path: 'tyre-retread/edit',
loadChildren: './views/tyre-retread/edit/tyre-retread-edit.module#TyreretreadEditModule'
},
{
path: 'tyre-retread/view',
loadChildren: './views/tyre-retread/view/tyre-retread-view.module#TyreretreadViewModule'
},
{
path: 'tyre-retread/print',
loadChildren: './views/tyre-retread/print/tyre-retread-print.module#TyreretreadPrintModule'
},
{
path: 'tyre-retread/receive',
loadChildren: './views/tyre-retread/receive/tyre-retread-receive.module#TyreretreadReceiveModule'
},
{
path: 'other-item-fitment',
loadChildren: './views/other-item-fitment/other-item-fitment.module#OtheritemfitmentModule'
},
{
path: 'other-item-fitment/create',
loadChildren: './views/other-item-fitment/create/other-item-fitment-create.module#OtheritemfitmentCreateModule'
},
{
path: 'other-item-fitment/view',
loadChildren: './views/other-item-fitment/view/other-item-fitment-view.module#OtheritemfitmentViewModule'
},
{
path: 'purchase',
loadChildren: './views/purchase/purchase.module#PurchaseModule'
},
{
path: 'purchase/create',
loadChildren: './views/purchase/create/purchase-create.module#PurchaseCreateModule'
},
{
path: 'purchase/edit',
loadChildren: './views/purchase/edit/purchase-edit.module#PurchaseEditModule'
},
{
path: 'purchase/view',
loadChildren: './views/purchase/view/purchase-view.module#PurchaseViewModule'
},
{
path: 'tag-master',
loadChildren: './views/tag-master/tag-master.module#TagmasterModule'
},
{
path: 'tag-master/import-tag',
loadChildren: './views/tag-master/import-tag/tag-master-import-tag.module#TagmasterImporttagModule'
},
{
path: 'tag-master/assign-tag',
loadChildren: './views/tag-master/assign-tag/tag-master-assign-tag.module#TagmasterAssigntagModule'
},
{
path: 'countrystatecity',
loadChildren: './views/countrystatecity/countrystatecity.module#CountrystatecityModule'
},
{
path: 'countrystatecity/create',
loadChildren: './views/countrystatecity/create/countrystatecity-create.module#CountrystatecityCreateModule'
},
{
path: 'countrystatecity/edit',
loadChildren: './views/countrystatecity/edit/countrystatecity-edit.module#CountrystatecityEditModule'
},
{
path: 'role-creation',
loadChildren: './views/role-creation/role-creation.module#RolecreationModule'
},
{
path: 'role-creation/create',
loadChildren: './views/role-creation/create/role-creation-create.module#RolecreationCreateModule'
},
{
path: 'role-creation/edit',
loadChildren: './views/role-creation/edit/role-creation-edit.module#RolecreationEditModule'
},
{
path: 'common-report',
loadChildren: './views/common-report/common-report.module#CommonreportModule'
},
{
path: 'gst1r',
loadChildren: './views/gstr1/gstr1.module#Gstr1Module'
},
{
path: 'gstr3b',
loadChildren: './views/gstr3b/gstr3b.module#Gstr3bModule'
},
{
path: 'fleeca-customer-agreement',
loadChildren: './views/fleeca-customer-agreement/fleeca-customer-agreement.module#FleecacustomeragreementModule'
},
{
path: 'fleeca-customer-agreement/create',
loadChildren: './views/fleeca-customer-agreement/create/fleeca-customer-agreement-create.module#FleecacustomeragreementCreateModule'
},
{
path: 'fleeca-customer-agreement/edit',
loadChildren: './views/fleeca-customer-agreement/edit/fleeca-customer-agreement-edit.module#FleecacustomeragreementEditModule'
},
{
path: 'trip-bill-generate',
loadChildren: './views/trip-bill-generate/trip-bill-generate.module#TripbillgenerateModule'
},
{
path: 'trip-bill-generate/create',
loadChildren: './views/trip-bill-generate/create/trip-bill-generate-create.module#TripbillgenerateCreateModule'
},
{
path: 'trip-bill-generate/print',
loadChildren: './views/trip-bill-generate/print/trip-bill-generate-print.module#TripbillgeneratePrintModule'
},   
{
path: 'fleeca-invoice',
loadChildren: './views/fleeca-invoice/fleeca-invoice.module#FleecainvoiceModule'
},
{
path: 'fleeca-invoice/create',
loadChildren: './views/fleeca-invoice/create/fleeca-invoice-create.module#FleecainvoiceCreateModule'
},
{
path: 'fleeca-invoice/print',
loadChildren: './views/fleeca-invoice/print/fleeca-invoice-print.module#FleecainvoicePrintModule'
}, 
{
path: 'fleeca-invoice/annexure',
loadChildren: './views/fleeca-invoice/annexure/fleeca-invoice-annexure.module#FleecainvoiceAnnexureModule'
}, 
{ 
path: 'fleeca-invoice/annexureadd',
loadChildren: './views/fleeca-invoice/annexureadd/fleeca-invoice-annexureadd.module#FleecainvoiceAnnexureaddModule'
},
{ 
path: 'material-dispatch-sale/annexureadd',
loadChildren: './views/material-dispatch-sale/annexureadd/dispatch-invoice-annexureadd.module#DispatchinvoiceAnnexureaddModule'
}, 

{
  path: 'fleeca-invoice/edit',
  loadChildren: './views/fleeca-invoice/edit/fleeca-invoice-edit.module#FleecainvoiceEditModule'
  }, 
{
path: 'salary-slip',
loadChildren: './views/salary-slip/salary-slip.module#SalaryslipModule'
},
{
path: 'salary-slip/create',
loadChildren: './views/salary-slip/create/salary-slip-create.module#SalaryslipCreateModule'
},
{
path: 'salary-slip/print',
loadChildren: './views/salary-slip/print/salary-slip-print.module#SalaryslipPrintModule'
},
{
path: 'salary-slip/edit',
loadChildren: './views/salary-slip/edit/salary-slip-edit.module#SalaryslipEditModule'
},
{
path: 'course-slip',
loadChildren: './views/course-slip/course-slip.module#CourseslipModule'
},
{
path: 'course-slip/create',
loadChildren: './views/course-slip/create/course-slip-create.module#CourseslipCreateModule'
},
{
path: 'course-slip/edit',
loadChildren: './views/course-slip/edit/course-slip-edit.module#CourseslipEditModule'
},     
{
path: 'pf-sheet',
loadChildren: './views/pf-sheet/pf-sheet.module#PfsheetModule'
},
{
path: 'bank-sheet',
loadChildren: './views/bank-sheet/bank-sheet.module#BanksheetModule'
},
{
path: 'hsn-code',
loadChildren: './views/hsn-code/hsn-code.module#HsncodeModule'
},
{
path: 'hsn-code/create',
loadChildren: './views/hsn-code/create/hsn-code-create.module#HsncodeCreateModule'
},
{
path: 'hsn-code/edit',
loadChildren: './views/hsn-code/edit/hsn-code-edit.module#HsncodeEditModule'
},
{
path: 'customer-price',
loadChildren: './views/customer-price/customer-price.module#CustomerpriceModule'
},
{
path: 'customer-price/create',
loadChildren: './views/customer-price/create/customer-price-create.module#CustomerpriceCreateModule'
},
{
path: 'customer-price/edit',
loadChildren: './views/customer-price/edit/customer-price-edit.module#CustomerpriceEditModule'
},
{
path: 'service-master',
loadChildren: './views/service-master/service-master.module#ServicemasterModule'
},
{
path: 'service-master/create',
loadChildren: './views/service-master/create/service-master-create.module#ServicemasterCreateModule'
},
{
path: 'service-master/edit',
loadChildren: './views/service-master/edit/service-master-edit.module#ServicemasterEditModule'
},
{
path: 'service-master/view',
loadChildren: './views/service-master/view/service-master-view.module#ServicemasterViewModule'
},
{
path: 'area-master',
loadChildren: './views/area-master/area-master.module#AreamasterModule'
},
{
path: 'area-master/create',
loadChildren: './views/area-master/create/area-master-create.module#AreamasterCreateModule'
},
{
path: 'area-master/edit',
loadChildren: './views/area-master/edit/area-master-edit.module#AreamasterEditModule'
},
{
path: 'area-master/view',
loadChildren: './views/area-master/view/area-master-view.module#AreamasterViewModule'
},
{
path: 'category-master',
loadChildren: './views/category-master/category-master.module#CategorymasterModule'
},
{
path: 'category-master/create',
loadChildren: './views/category-master/create/category-master-create.module#CategorymasterCreateModule'
},
{
path: 'category-master/edit',
loadChildren: './views/category-master/edit/category-master-edit.module#CategorymasterEditModule'
},
{
  path: 'item-category-master',
  loadChildren: './views/item-category-master/item-category-master.module#ItemCategorymasterModule'
  },
  {
  path: 'item-category-master/create',
  loadChildren: './views/item-category-master/create/item-category-master-create.module#ItemCategorymasterCreateModule'
  },
  {
  path: 'item-category-master/edit',
  loadChildren: './views/item-category-master/edit/item-category-master-edit.module#ItemCategorymasterEditModule'
  },
{
path: 'job-card',
loadChildren: './views/job-card/job-card.module#JobcardModule'
},
{
path: 'job-card/view',
loadChildren: './views/job-card/view/job-card-view.module#JobcardViewModule'
},
{
path: 'job-card/edit',
loadChildren: './views/job-card/edit/job-card-edit.module#JobcardEditModule'
},
{
path: 'job-card/create',
loadChildren: './views/job-card/create/job-card-create.module#JobcardCreateModule'
},
{
path: 'job-card/create-request',
loadChildren: './views/job-card/createreq/vehicle-request-create.module#VehiclerequestCreateModule'
},
{
  path: 'approval-job-card',
  loadChildren: './views/approval-job-card/approval-job-card.module#ApprovalJobcardModule'
},
{
path: 'tyre-expense',
loadChildren: './views/tyre-expense/tyre-expense.module#TyreexpenseModule'
},
{
path: 'tyre-expense/create',
loadChildren: './views/tyre-expense/create/tyre-expense-create.module#TyreexpenseCreateModule'
},
{
path: 'tyre-expense/edit',
loadChildren: './views/tyre-expense/edit/tyre-expense-edit.module#TyreexpenseEditModule'
},
{
path: 'tyre-purchase-updation',
loadChildren: './views/tyre-purchase-updation/tyre-purchase-updation.module#TyrepurchaseupdationModule'
},
{
path: 'tyre-purchase-updation/edit',
loadChildren: './views/tyre-purchase-updation/edit/tyre-purchase-updation-edit.module#TyrepurchaseupdationEditModule'
},
{
path: 'tyre-purchase-updation/create',
loadChildren: './views/tyre-purchase-updation/create/tyre-purchase-updation-create.module#TyrepurchaseupdationCreateModule'
},
{
path: 'vehiclemasterimport',
loadChildren: './views/vehiclemasterimport/vehiclemasterimport.module#VehiclemasterimportModule'
},
{
path: 'fleeca-center-invoice',
loadChildren: './views/fleeca-center-invoice/fleeca-center-invoice.module#FleecacenterinvoiceModule'
},
{
path: 'fleeca-center-invoice/print',
loadChildren: './views/fleeca-center-invoice/print/fleeca-center-invoice-print.module#FleecacenterinvoicePrintModule'
},
{
path: 'fleeca-center-invoice/printt',
loadChildren: './views/fleeca-center-invoice/printt/fleeca-center-invoice-printt.module#FleecacenterinvoicePrinttModule'
},
{
path: 'fleeca-center-invoice/fc-create-invoice',
loadChildren: './views/fleeca-center-invoice/fc-create-invoice/fc-create-invoice.module#FCCreateInvoiceModule'
},
{
path: 'fleeca-center-customer-invoice',
loadChildren: './views/fleeca-center-customer-invoice/fleeca-center-customer-invoice.module#FleecacentercustomerinvoiceModule'
},
{
path: 'fleeca-center-customer-invoice/print',
loadChildren: './views/fleeca-center-customer-invoice/print/fleeca-center-customer-invoice-print.module#FleecacentercustomerinvoicePrintModule'
},
{
path: 'fleeca-center-customer-invoice/printt',
loadChildren: './views/fleeca-center-customer-invoice/printt/fleeca-center-customer-invoice-printt.module#FleecacentercustomerinvoicePrinttModule'
},
{
path: 'fleeca-center-customer-invoice/create-invoice',
loadChildren: './views/fleeca-center-customer-invoice/create-invoice/create-invoice.module#CreateInvoiceModule'
},
{
path: 'service-request',
loadChildren: './views/service-request/service-request.module#ServiceRequestModule'
},
{
path: 'service-request/print',
loadChildren: './views/service-request/print/service-request-print.module#ServiceRequestPrintModule'
},
{
path: 'update-pending-status',
loadChildren: './views/update-pending-status/update-pending-status.module#UpdatependingstatusModule'
},
{
path: 'passwords',
loadChildren: './views/passwords/passwords.module#PasswordsModule'
},
{
path: 'scrap-tyre',
loadChildren: './views/scrap-tyre/scrap-tyre.module#ScraptyreModule'
},
{
path: 'scrap-tyre/create',
loadChildren: './views/scrap-tyre/create/scrap-tyre-create.module#ScraptyreCreateModule'
},
{
path: 'scrap-tyre/edit',
loadChildren: './views/scrap-tyre/edit/scrap-tyre-edit.module#ScraptyreEditModule'
},
{
path: 'tyre-fitment',
loadChildren: './views/tyre-fitment/tyre-fitment.module#TyreFitmentModule'
},
{
path: 'tyre-fitment/fitment',
loadChildren: './views/tyre-fitment/fitment/vehicles-fitment.module#VehiclesFitmentModule'
},
{
path: 'reports',
loadChildren: './views/reports/reports.module#ReportsModule'
},
{
path: 'tpms-data-reports',
loadChildren: './views/tpms-data-reports/tpms-data-reports.module#TpmsdatareportsModule'
},
{
path: 'tpms-reports',
loadChildren: './views/tpms-reports/tpms-reports.module#TpmsreportsModule'
},
{
  path: 'gps-data-reports',
  loadChildren: './views/gps-data-reports/gps-data-reports.module#GPSdatareportsModule'
},
{
path: 'userpoint',
loadChildren: './views/userpoint/userpoint.module#UserpointModule'
},
{
path: 'userpoint/edit',
loadChildren: './views/userpoint/edit/userpoint-edit.module#UserpointEditModule'
},
{
path: 'userpoint/view',
loadChildren: './views/userpoint/view/userpoint-view.module#UserpointViewModule'
},
{
path: 'payment-voucher',
loadChildren: './views/payment-voucher/payment-voucher.module#PaymentvoucherModule'
},
{
path: 'payment-voucher/create',
loadChildren: './views/payment-voucher/create/payment-voucher-create.module#PaymentvoucherCreateModule'
}, 
{
path: 'receipt-voucher',
loadChildren: './views/receipt-voucher/receipt-voucher.module#ReceiptvoucherModule'
},
{
path: 'receipt-voucher/create',
loadChildren: './views/receipt-voucher/create/receipt-voucher-create.module#ReceiptvoucherCreateModule'
},
{
path: 'guest-users',
loadChildren: './views/guest-users/guest-users.module#GuestusersModule'
},
{
path: 'guest-users/create',
loadChildren: './views/guest-users/create/guest-users-create.module#GuestusersCreateModule'
},
{
path: 'guest-users/edit',
loadChildren: './views/guest-users/edit/guest-users-edit.module#GuestusersEditModule'
},
{
path: 'guest-users/view',
loadChildren: './views/guest-users/view/guest-users-view.module#GuestusersViewModule'
},
{
path: 'rules',
loadChildren: './views/rules/rules.module#RulesModule'
},
{
path: 'rules/create',
loadChildren: './views/rules/create/rules-create.module#RulesCreateModule'
},
{
path: 'rules/edit',
loadChildren: './views/rules/edit/rules-edit.module#RulesEditModule'
},
{
path: 'rules/view',
loadChildren: './views/rules/view/rules-view.module#RulesViewModule'
},
{
path: 'ledger-creation',
loadChildren: './views/ledger-creation/ledger-creation.module#LedgercreationModule'
},
{
path: 'ledger-creation/create',
loadChildren: './views/ledger-creation/create/ledger-creation-create.module#LedgercreationCreateModule'
},
{
path: 'ledger-creation/edit',
loadChildren: './views/ledger-creation/edit/ledger-creation-edit.module#LedgercreationEditModule'
},
{
  path: 'account-group',
  loadChildren: './views/account-group/account-group.module#AccountgroupModule'
  },
  {
  path: 'account-group/create',
  loadChildren: './views/account-group/create/account-group-create.module#AccountgroupCreateModule'
  },
  {
  path: 'account-group/edit',
  loadChildren: './views/account-group/edit/account-group-edit.module#AccountgroupEditModule'
  },
{
path: 'tpms-stock-master',
loadChildren: './views/tpms-stock-master/tpms-stock-master.module#TpmsStockMasterModule'
},
{
path: 'tpms-stock-master/create',
loadChildren: './views/tpms-stock-master/create/tpms-stock-master-create.module#TpmsStockMasterCreateModule'
},
{
path: 'tpms-stock-master/edit',
loadChildren: './views/tpms-stock-master/edit/tpms-stock-master-edit.module#TpmsStockMasterEditModule'
},
{
path: 'fleeca-center-customer-price',
loadChildren: './views/fleeca-center-customer-price/fleeca-center-customer-price.module#FleecacentercustomerpriceModule'
},
{
path: 'fleeca-center-customer-price/create',
loadChildren: './views/fleeca-center-customer-price/create/fleeca-center-customer-price-create.module#FleecacentercustomerpriceCreateModule'
},
{
path: 'fleeca-center-customer-price/edit',
loadChildren: './views/fleeca-center-customer-price/edit/fleeca-center-customer-price-edit.module#FleecacentercustomerpriceEditModule'
},
{
path: 'fleeca-center-customer-price/view',
loadChildren: './views/fleeca-center-customer-price/view/fleeca-center-customer-price-view.module#FleecacentercustomerpriceViewModule'
},
{
path: 'fleeca-center-price',
loadChildren: './views/fleeca-center-price/fleeca-center-price.module#FleecacenterpriceModule'
},
{
path: 'fleeca-center-price/create',
loadChildren: './views/fleeca-center-price/create/fleeca-center-price-create.module#FleecacenterpriceCreateModule'
},
{
path: 'fleeca-center-price/edit',
loadChildren: './views/fleeca-center-price/edit/fleeca-center-price-edit.module#FleecacenterpriceEditModule'
},
{
path: 'fleeca-center-price/view',
loadChildren: './views/fleeca-center-price/view/fleeca-center-price-view.module#FleecacenterpriceViewModule'
},
{
path: 'sod',
loadChildren: './views/sod/sod.module#SODModule'
},
{
path: 'sod/create',
loadChildren: './views/sod/create/sod-create.module#SODCreateModule'
},
{
path: 'sod/edit',
loadChildren: './views/sod/edit/sod-edit.module#SODEditModule'
},
{
path: 'sod/view',
loadChildren: './views/sod/view/sod-view.module#SODViewModule'
}, 
{
path: 'rules',
loadChildren: './views/rules/rules.module#RulesModule'
},
{
path: 'rules/create',
loadChildren: './views/rules/create/rules-create.module#RulesCreateModule'
},
{
path: 'rules/edit',
loadChildren: './views/rules/edit/rules-edit.module#RulesEditModule'
},
{
path: 'rules/view',
loadChildren: './views/rules/view/rules-view.module#RulesViewModule'
},   
{
path: 'retreading-gate-in',
loadChildren: './views/retreading-gate-in/retreading-gate-in.module#RetreadinggateinModule'
},
{
path: 'retreading-gate-in/create',
loadChildren: './views/retreading-gate-in/create/retreading-gate-in-create.module#RetreadinggateinCreateModule'
},
{
path: 'retreading-gate-in/edit',
loadChildren: './views/retreading-gate-in/edit/retreading-gate-in-edit.module#RetreadinggateinEditModule'
},
{
path: 'retreading-gate-in/view',
loadChildren: './views/retreading-gate-in/view/retreading-gate-in-view.module#RetreadinggateinViewModule'
},   
{
path: 'retreading-gate-out',
loadChildren: './views/retreading-gate-out/retreading-gate-out.module#RetreadinggateoutModule'
},
{
path: 'retreading-gate-out/create',
loadChildren: './views/retreading-gate-out/create/retreading-gate-out-create.module#RetreadinggateoutCreateModule'
},
{
path: 'retreading-gate-out/edit',
loadChildren: './views/retreading-gate-out/edit/retreading-gate-out-edit.module#RetreadinggateoutEditModule'
},
{
path: 'retreading-gate-out/view',
loadChildren: './views/retreading-gate-out/view/retreading-gate-out-view.module#RetreadinggateoutViewModule'
},  
{
path: 'vehicle-setup',
loadChildren: './views/vehicle-setup/vehicle-setup.module#VehiclesetupModule'
},
{
path: 'vehicle-setup/create',
loadChildren: './views/vehicle-setup/create/vehicle-setup-create.module#VehiclesetupCreateModule'
},
{
path: 'vehicle-setup/edit',
loadChildren: './views/vehicle-setup/edit/vehicle-setup-edit.module#VehiclesetupEditModule'
},
{
path: 'vehicle-setup/view',
loadChildren: './views/vehicle-setup/view/vehicle-setup-view.module#VehiclesetupViewModule'
},
{
  path: 'tag-setup',
  loadChildren: './views/tag-setup/tag-setup.module#TagsetupModule'
  },
  {
  path: 'tag-setup/create',
  loadChildren: './views/tag-setup/create/tag-setup-create.module#TagsetupCreateModule'
  },
  {
    path: 'tag-setup/edit',
    loadChildren: './views/tag-setup/edit/tag-setup-edit.module#TagsetupEditModule'
    },
{
path: 'vehicle-tag-fitment',
loadChildren: './views/vehicle-tag-fitment/vehicle-tag-fitment.module#VehicletagfitmentModule'
},
{
path: 'vehicle-tag-fitment/create',
loadChildren: './views/vehicle-tag-fitment/create/vehicle-tag-fitment-create.module#VehicletagfitmentCreateModule'
},   
{
path: 'fleecacenter-transaction-report',
loadChildren: './views/fleecacenter-transaction-report/fleecacenter-transaction-report.module#FleecacentertransactionreportModule'
},
{
path: 'user-log',
loadChildren: './views/user-log/user-log.module#UserlogModule'
},
{
path: 'conversation-report',
loadChildren: './views/conversation-report/conversation-report.module#ConversationreportModule'
},
{
path: 'conversation-report/create',
loadChildren: './views/conversation-report/create/conversation-report-create.module#ConversationreportCreateModule'
},
{
path: 'fleecacenter-conversation-report',
loadChildren: './views/fleecacenter-conversation-report/fleecacenter-conversation-report.module#FleecacenterConversationReportModule'
},
{
path: 'fleecacenter-conversation-report/create',
loadChildren: './views/fleecacenter-conversation-report/create/fleecacenter-conversation-report-create.module#FleecacenterConversationreportCreateModule'
},
{
  path: 'fleecacenter-conversation-report/view',
  loadChildren: './views/fleecacenter-conversation-report/view/fleecacenter-conversation-report-view.module#FleecacenterConversationreportViewModule'
  },
{
path: 'center-revenue-report',
loadChildren: './views/center-revenue-report/center-revenue-report.module#CenterrevenuereportModule'
},
{
path: 'growth-report',
loadChildren: './views/growth-report/growth-report.module#GrowthreportModule'
},
{
path: 'incentive-report',
loadChildren: './views/incentive-report/incentive-report.module#IncentiveReportModule'
},
{
path: 'tyrecasing-report',
loadChildren: './views/tyrecasing-report/tyrecasing-report.module#TyrecasingreportModule'
},
{
path: 'tyrecasing-report/view',
loadChildren: './views/tyrecasing-report/view/tyrecasing-report-view.module#TyrecasingreportViewModule'
},
{
path: 'pdf-report',
loadChildren: './views/pdf-report/pdf-report.module#PdfreportModule'
},
{
path: 'fleeca-center-dashboard',
loadChildren: './views/fleeca-center-dashboard/fleeca-center-dashboard.module#FleecaCenterdashboardModule'
},
{
path: 'fleeca-center-crm',
loadChildren: './views/fleeca-center-crm/fleeca-center-crm.module#FleecaCenterCrmModule'
},
{
path: 'fleeca-center-crm/reports',
loadChildren: './views/fleeca-center-crm/reports/fleeca-center-reports.module#FleecacenterReportsModule'
},
{
path: 'sales-crm',
loadChildren: './views/sales-crm/sales-crm.module#SalesCrmModule'
},
{
path: 'tyrecasing-report/buy',
loadChildren: './views/tyrecasing-report/buy/tyrecasing-report-buy.module#TyrecasingreportBuyModule'
},
{
path: 'send-report-history',
loadChildren: './views/send-report-history/send-report-history.module#SendreporthistoryModule'
},
{
path: 'notification',
loadChildren: './views/notification/notification.module#NotificationModule'
},
{
path: 'notification/send',
loadChildren: './views/notification/create/notification-create.module#NotificationCreateModule'
},
{
path: 'notification/view',
loadChildren: './views/notification/view/notification-view.module#NotificationViewModule'
},
{
path: 'tpms-notifications',
loadChildren: './views/tpms-notifications/tpms-notifications.module#TpmsNotificationModule'
},
{
path: 'ticket-history',
loadChildren: './views/ticket-history/ticket-history.module#TicketHistoryModule'
},
{
path: 'service-engineer-mapping',
loadChildren: './views/service-engineer-mapping/service-engineer-mapping.module#ServiceEngineerMappingModule'
},
{
path: 'service-engineer-mapping/view',
loadChildren: './views/service-engineer-mapping/view/service-engineer-mapping-view.module#ServiceEngineerMappingViewModule'
},
{
path: 'request-demo',
loadChildren: './views/request-demo/request-demo.module#RequestdemoModule'
},
{
path: 'check-in-out',
loadChildren: './views/check-in-out/check-in-out.module#CheckinoutModule'
},
{
path: 'offers',
loadChildren: './views/offer/offer.module#OfferModule'
},
{
path: 'offers/create',
loadChildren: './views/offer/create/offer-create.module#OfferCreateModule'
},
{
path: 'offers/edit',
loadChildren: './views/offer/edit/offer-edit.module#OfferEditModule'
},
{
path: 'campaign',
loadChildren: './views/campaign/campaign.module#CampaignModule'
},
{
path: 'campaign/create',
loadChildren: './views/campaign/create/campaign-create.module#CampaignCreateModule'
},
{
path: 'campaign/edit',
loadChildren: './views/campaign/edit/campaign-edit.module#CampaignEditModule'
},
{
path: 'campaign/view',
loadChildren: './views/campaign/view/campaign-view.module#CampaignViewModule'
},
{
path: 'campaign-list',
loadChildren: './views/campaign-list/campaign-list.module#CampaignListModule'
},
{
path: 'campaign-list/distribute',
loadChildren: './views/campaign-list/distribute/campaign-list-distribute.module#CampaignListDistributeModule'
},
{
path: 'campaign-list/view',
loadChildren: './views/campaign-list/view/campaign-list-view.module#CampaignListViewModule'
}, 
{
path: 'update-vehiclemou',
loadChildren: './views/update-vehiclemou/update-vehiclemou.module#UpdatevehiclemouModule'
},
{
path: 'update-vehiclemou/create',
loadChildren: './views/update-vehiclemou/create/update-vehiclemou-create.module#UpdatevehiclemouCreateModule'
},
{
path: 'update-vehiclemou/edit',
loadChildren: './views/update-vehiclemou/edit/update-vehiclemou-edit.module#UpdatevehiclemouEditModule'
},
{
path: 'cpkmdatasheet',
loadChildren: './views/cpkmdatasheet/cpkmdatasheet.module#CpkmDataSheetModule'
},
{
path: 'item-master',
loadChildren: './views/item-master/item-master.module#ItemmasterModule'
},
{
path: 'item-master/create',
loadChildren: './views/item-master/create/item-master-create.module#ItemmasterCreateModule'
},
{
path: 'item-master/edit',
loadChildren: './views/item-master/edit/item-master-edit.module#ItemmasterEditModule'
},
{
path: 'item-master/view',
loadChildren: './views/item-master/view/item-master-view.module#ItemmasterViewModule'
},
{
  path: 'item-mapping',
  loadChildren: './views/item-mapping/item-mapping.module#ItemmappingModule'
  },
  {
  path: 'item-mapping/create',
  loadChildren: './views/item-mapping/create/item-mapping-create.module#ItemmappingCreateModule'
  },
  {
  path: 'item-mapping/edit',
  loadChildren: './views/item-mapping/edit/item-mapping-edit.module#ItemmappingEditModule'
  },
{
path: 'item-conversion-master',
loadChildren: './views/item-conversion-master/item-conversion-master.module#ItemConversionMasterModule'
},
{
path: 'item-conversion-master/create',
loadChildren: './views/item-conversion-master/create/item-conversion-master-create.module#ItemConversionMasterCreateModule'
},
{
path: 'purchase-order',
loadChildren: './views/purchase-order/purchase-order.module#PurchaseorderModule'
},
{
path: 'purchase-order/create',
loadChildren: './views/purchase-order/create/purchase-order-create.module#PurchaseorderCreateModule'
},
{
path: 'purchase-order/edit',
loadChildren: './views/purchase-order/edit/purchase-order-edit.module#PurchaseorderEditModule'
},
{
path: 'purchase-order/view',
loadChildren: './views/purchase-order/view/purchase-order-view.module#PurchaseorderViewModule'
},
{
path: 'purchase-order/annexureadd',
loadChildren: './views/purchase-order/annexureadd/purchase-order-annexureadd.module#PurchaseorderAnnexureaddModule'
},
{
  path: 'po-order',
  loadChildren: './views/po-order/po-order.module#PoorderModule'
  },
  {
  path: 'po-order/create',
  loadChildren: './views/po-order/create/po-order-create.module#PoorderCreateModule'
  },
  {
    path: 'po-order/edit',
    loadChildren: './views/po-order/edit/po-order-edit.module#PoorderEditModule'
    }, 
{
path: 'purchase-stock',
loadChildren: './views/purchase-stock/purchase-stock.module#PurchasestockModule'
},
{
path: 'purchase-stock/create',
loadChildren: './views/purchase-stock/create/purchase-stock-create.module#PurchasestockCreateModule'
},
{
path: 'purchase-stock/edit',
loadChildren: './views/purchase-stock/edit/purchase-stock-edit.module#PurchasestockEditModule'
},
{
path: 'purchase-stock/edit-all',
loadChildren: './views/purchase-stock/edit-all/purchase-stock-edit-all.module#PurchasestockEditAllModule'
},
{
path: 'purchase-stock/view',
loadChildren: './views/purchase-stock/view/purchase-stock-view.module#PurchasestockViewModule'
},
{
  path: 'purchase-stock-nopo/create',
  loadChildren: './views/purchase-stock/without-po/purchase-stock-nopo-create.module#PurchasestockNopoCreateModule'
  },
{
path: 'material-requisition',
loadChildren: './views/material-requisition/material-requisition.module#MaterialRequisitionModule'
},
{
path: 'material-requisition/create',
loadChildren: './views/material-requisition/create/material-requisition-create.module#MaterialRequisitionCreateModule'
},
{
path: 'material-requisition/edit',
loadChildren: './views/material-requisition/edit/material-requisition-edit.module#MaterialRequisitionEditModule'
},
{
path: 'service-engg-report',
loadChildren: './views/service-engg-report/service-engg-report.module#ServiceEnggReportModule'
},   
{
path: 'outstanding-report',
loadChildren: './views/outstanding-report/outstanding-report.module#OutstandingReportModule'
},
{
path: 'fleeca-center-analysis',
loadChildren: './views/fleeca-center-analysis/fleeca-center-analysis.module#FleecaCenterAnalysisModule'
},  
{
path: 'service-engg-report/print',
loadChildren: './views/service-engg-report/print/service-engg-report-print.module#ServiceEnggReportPrintModule'
},
{
path: 'service-engg-report/printsite',
loadChildren: './views/service-engg-report/printsite/service-engg-report-printsite.module#ServiceEnggReportPrintsiteModule'
},
{
path: 'fc-payment-receive',
loadChildren: './views/fc-payment-receive/fc-payment-receive.module#FcPaymentReceiveModule'
},
{
path: 'fc-payment-receive/create',
loadChildren: './views/fc-payment-receive/create/fc-payment-receive-create.module#FcPaymentReceiveCreateModule'
},
{
path: 'stock-error',
loadChildren: './views/stock-error/stock-error.module#StockErrorModule'
},
{
  path: 'prepaid-plan-master',
  loadChildren: './views/prepaid-plan-master/prepaid-plan-master.module#PrepaidplanmasterModule'
  },
  {
  path: 'prepaid-plan-master/create',
  loadChildren: './views/prepaid-plan-master/create/prepaid-plan-master-create.module#PrepaidplanmasterCreateModule'
  },
  {
  path: 'prepaid-plan-master/edit',
  loadChildren: './views/prepaid-plan-master/edit/prepaid-plan-master-edit.module#PrepaidplanmasterEditModule'
  },
  {
    path: 'prepaid-plan-master/view',
    loadChildren: './views/prepaid-plan-master/view/prepaid-plan-master-view.module#PrepaidplanmasterViewModule'
    },
    {
      path: 'prepaid-plan-master/edit',
      loadChildren: './views/prepaid-plan-master/edit/prepaid-plan-master-edit.module#PrepaidplanmasterEditModule'
      },
  {
  path: 'prepaid-client-mapping/create',
  loadChildren: './views/prepaid-client-mapping/create/prepaid-client-mapping-create.module#PrepaidclientMappingCreateModule'
  },
  {
  path: 'prepaid-client-mapping',
  loadChildren: './views/prepaid-client-mapping/prepaid-client-mapping.module#PrepaidclientMappingModule'
  },
  {
    path: 'prepaid-client-mapping/view',
    loadChildren: './views/prepaid-client-mapping/view/prepaid-client-mapping-view.module#PrepaidclientMappingViewModule'
    },
{
path: 'campaign-enquiry',
loadChildren: './views/campaign-enquiry/campaign-enquiry.module#CampaignEnquiryModule'
},
{
path: 'project-management',
loadChildren: './views/project-management/project-management.module#ProjectManagementModule'
},
{
path: 'project-management/create',
loadChildren: './views/project-management/create/project-management-create.module#ProjectManagementCreateModule'
},
{
path: 'project-management/edit',
loadChildren: './views/project-management/edit/project-management-edit.module#ProjectManagementEditModule'
},
{
path: 'project-management/task-queue',
loadChildren: './views/project-management/task-queue/project-management-task-queue.module#TaskQueueModule'
},
{
path: 'project-management/task-master',
loadChildren: './views/project-management/task-master/project-management-task-master.module#TaskMasterModule'
},
{
path: 'project-management/task-master/create',
loadChildren: './views/project-management/task-master/create/project-management-task-master-create.module#TaskMasterCreateModule'
},
{
path: 'project-management/task-master/edit',
loadChildren: './views/project-management/task-master/edit/project-management-task-master-edit.module#TaskMasterEditModule'
},
{
  path: 'project-management/target-description-master',
  loadChildren: './views/project-management/target-description-master/project-management-target-description-master.module#TargetDescriptionMasterModule'
  },
  {
  path: 'project-management/target-description-master/create',
  loadChildren: './views/project-management/target-description-master/create/project-management-target-description-master-create.module#TargetDescriptionMasterCreateModule'
  },
  {
  path: 'project-management/target-description-master/edit',
  loadChildren: './views/project-management/target-description-master/edit/project-management-target-description-master-edit.module#TargetDescriptionMasterEditModule'
  },
{
path: 'project-management/task-period-master',
loadChildren: './views/project-management/task-period-master/project-management-task-period-master.module#TaskPeriodMasterModule'
},
{
path: 'project-management/task-period-master/create',
loadChildren: './views/project-management/task-period-master/create/project-management-task-period-master-create.module#TaskPeriodMasterCreateModule'
},
{
path: 'project-management/task-period-master/edit',
loadChildren: './views/project-management/task-period-master/edit/project-management-task-period-master-edit.module#TaskPeriodMasterEditModule'
},
{
path: 'project-management/project-category-master',
loadChildren: './views/project-management/project-category-master/project-management-project-category-master.module#ProjectCategorymasterModule'
},
{
path: 'project-management/project-category-master/create',
loadChildren: './views/project-management/project-category-master/create/project-management-project-category-master-create.module#ProjectCategorymasterCreateModule'
},
{
path: 'project-management/project-category-master/edit',
loadChildren: './views/project-management/project-category-master/edit/project-management-project-category-master-edit.module#ProjectCategorymasterEditModule'
},
{
path: 'process-master',
loadChildren: './views/process-master/process-master.module#ProcessMasterModule'
},
{
path: 'process-master/create',
loadChildren: './views/process-master/create/process-master-create.module#ProcessMasterCreateModule'
},
{
path: 'process-master/edit',
loadChildren: './views/process-master/edit/process-master-edit.module#ProcessMasterEditModule'
},
{
path: 'process-start',
loadChildren: './views/process-start/process-start.module#ProcessStartModule'
},
{
path: 'process-start/create',
loadChildren: './views/process-start/create/process-start-create.module#ProcessStartCreateModule'
},
{
path: 'process-start/edit',
loadChildren: './views/process-start/edit/process-start-edit.module#ProcessStartEditModule'
},
{
path: 'process-cpkm',
loadChildren: './views/process-cpkm/process-cpkm.module#ProcessCpkmModule'
},
{
path: 'process-cpkm/edit',
loadChildren: './views/process-cpkm/edit/process-cpkm-edit.module#ProcessCpkmEditModule'
},
{
  path: 'training-management',
  loadChildren: './views/training-management/training-management.module#TrainingManagementModule'
},
{
  path: 'training-management/view',
  loadChildren: './views/training-management/view/training-management-view.module#TrainingManagementViewModule'
},
{
  path: 'training-management/course-view',
  loadChildren: './views/training-management/course-view/training-management-course-view.module#TrainingManagementCourseViewModule'
},
{
  path: 'training-management/create',
  loadChildren: './views/training-management/create/training-management-create.module#TrainingManagementCreateModule'
},
{
  path: 'training-management/edit',
  loadChildren: './views/training-management/edit/training-management-edit.module#TrainingManagementEditModule'
},
{
  path: 'training-management/training-history',
  loadChildren: './views/training-management/training-history/training-history.module#TrainingHistoryModule'
},
{
  path: 'training-management/training-history/view',
  loadChildren: './views/training-management/training-history/view/training-history-view.module#TrainingHistoryViewModule'
},
{
path: 'project-management/user-task',
loadChildren: './views/project-management/user-task/project-management-user-task.module#UserTaskMasterModule'
},
{
path: 'project-management/user-task/task-queue',
loadChildren: './views/project-management/user-task/task-queue/project-management-user-task-task-queue.module#UserTaskTaskQueueModule'
},
{
  path: 'project-management/user-task/multi-task',
  loadChildren: './views/project-management/user-task/multi-task/project-management-user-task-multi-task.module#UserTaskMultiTaskModule'
  },
{
path: 'batch-close-feedback',
loadChildren: './views/batch-close-feedback/batch-close-feedback.module#BatchCloseFeedbackModule'
},
{
path: 'batch-close-feedback/create',
loadChildren: './views/batch-close-feedback/create/batch-close-feedback-create.module#BatchCloseFeedbackCreateModule'
},
{
path: 'batch-close-feedback/edit',
loadChildren: './views/batch-close-feedback/edit/batch-close-feedback-edit.module#BatchCloseFeedbackEditModule'
},
{
path: 'batch-close-feedback/view',
loadChildren: './views/batch-close-feedback/view/batch-close-feedback-view.module#BatchCloseFeedbackViewModule'
},
{
path: 'site-audit',
loadChildren: './views/site-audit/site-audit.module#SiteAuditModule'
},
{
path: 'site-audit/create',
loadChildren: './views/site-audit/create/site-audit-create.module#SiteAuditCreateModule'
},
{
path: 'site-audit/edit',
loadChildren: './views/site-audit/edit/site-audit-edit.module#SiteAuditEditModule'
},
{
path: 'site-audit/view',
loadChildren: './views/site-audit/view/site-audit-view.module#SiteAuditViewModule'
},
{
path: 'departments',
loadChildren: './views/departments/departments.module#DepartmentsModule'
},
{
path: 'departments/create',
loadChildren: './views/departments/create/departments-create.module#DepartmentsCreateModule'
},
{
path: 'departments/edit',
loadChildren: './views/departments/edit/departments-edit.module#DepartmentsEditModule'
},
{
path: 'designations',
loadChildren: './views/designations/designations.module#DesignationsModule'
},
{
path: 'designations/create',
loadChildren: './views/designations/create/designations-create.module#DesignationsCreateModule'
},
{
path: 'designations/edit',
loadChildren: './views/designations/edit/designations-edit.module#DesignationsEditModule'
},
{
  path: 'approval-pending-lead',
  loadChildren: './views/approval-pending-lead/approval-pending-lead.module#ApprovalPendingModule'
},
{
  path: 'approval/purchaseorder',
  loadChildren: './views/approval/purchaseorder/purchaseorder.module#PurchaseorderModule'
},

{
  path: 'approval/grn-approval',
  loadChildren: './views/approval/grn-approval/grn-approval.module#GrnApprovalModule'
},
{
  path: 'approval/approvalprojectmanagement',
  loadChildren: './views/approval/approvalprojectmanagement/approvalprojectmanagement.module#ApprovalProjectManagementModule'
},
{
  path: 'approval/approvalbatchclosefeedback',
  loadChildren: './views/approval/approvalbatchclosefeedback/approvalbatchclosefeedback.module#ApprovalBatchCloseFeedbackModule'
},
{
  path: 'approval/channel-partner',
  loadChildren: './views/approval/channel-partner/channel-partner.module#ChannelpartnerModule'
},
{
  path: 'approval/materialrequisition',
  loadChildren: './views/approval/materialrequisition/materialrequisition.module#MaterialrequisitionModule'
},
{
  path: 'approval/siteaudit',
  loadChildren: './views/approval/site-audit/site-audit.module#SiteAuditModule'
},
{
  path: 'approval/stock-transfer-approval',
  loadChildren: './views/approval/stock-transfer/stock-transfer.module#StocktransferModule'
},
{
  path: 'approval/dispatch',
  loadChildren: './views/approval/dispatch/dispatch.module#DispatchModule'
},
{
path: 'rotation-report',
loadChildren: './views/rotation-report/rotation-report.module#RotationreportModule'
}, 
{
path: 'rotation-report/view',
loadChildren: './views/rotation-report/view/rotation-report-view.module#RotationreportViewModule'
},
{
  path: 'driver-report',
  loadChildren: './views/driver-report/driver-report.module#DriverreportModule'
},
{
  path: 'observation-and-action',
  loadChildren: './views/observation-and-action/observation-and-action.module#ObservationAndActionModule'
},
{
  path: 'observation-and-action/view',
  loadChildren: './views/observation-and-action/view/observation-and-action-view.module#ObservationAndActionViewModule'
},
{
  path: 'vehicle-getin-getout-report',
  loadChildren: './views/vehicle-getin-getout-report/vehicle-getin-getout-report.module#VehicleGetinGetoutreportModule'
  }, 
  {
  path: 'vehicle-getin-getout-report/view',
  loadChildren: './views/vehicle-getin-getout-report/view/vehicle-getin-getout-report-view.module#VehicleGetinGetoutreportViewModule'
  },
  {
  path: 'center-services-analytics',
  loadChildren: './views/center-services-analytics/center-services-analytics.module#CenterServicesAnalyticsModule'
  }, 
    {
  path: 'user-update-log',
  loadChildren: './views/user-update-log/user-update-log.module#UserUpdateLogModule'
  }, 
  {
  path: 'fleeca-center-invoice/edit',
  loadChildren: './views/fleeca-center-invoice/edit/fleeca-center-invoice-edit.module#FleecaCenterInvoiceEditModule'
  }, 
{
  path: 'tyre-inspection',
  loadChildren: './views/tyre-inspection/tyre-inspection.module#TyreInspectionModule'
  }, 
{
  path: 'approval/retread-order',
  loadChildren: './views/approval/retread-order/retread-order.module#RetreadOrderModule'
},  

{
  path: 'tyre-retread-order',
  loadChildren: './views/tyre-retread-order/tyre-retread-order.module#TyreRetreadOrderModule'
}, 
{
path: 'tyre-retread-order/create',
loadChildren: './views/tyre-retread-order/create/tyre-retread-order-create.module#TyreRetreadOrderCreateModule'
},
{
  path: 'approval/retread-sent',
  loadChildren: './views/approval/retread-sent/retread-sent.module#RetreadSentModule'
}, 
{
  path: 'approval/delivery-challan',
  loadChildren: './views/approval/delivery-challan/delivery-challan.module#DeliveryChallanModule'
},  
{
path: 'retreaderdetail',
loadChildren: './views/retreaderdetail/retreaderdetail.module#RetreaderdetailModule'
},
{
path: 'retreaderdetail/create',
loadChildren: './views/retreaderdetail/create/retreaderdetail-create.module#RetreaderdetailCreateModule'
},
{
path: 'retreaderdetail/edit',
loadChildren: './views/retreaderdetail/edit/retreaderdetail-edit.module#RetreaderdetailEditModule'
},
{
path: 'retreaderdetail/view',
loadChildren: './views/retreaderdetail/view/retreaderdetail-view.module#RetreaderdetailViewModule'
}, 
{
path: 'km-range-report',
loadChildren: './views/km-range-report/km-range-report.module#KmRangeReportModule'
},
{
path: 'lead-creation/cpkmupdate',
loadChildren: './views/lead-creation/cpkmupdate/lead-creation-cpkmupdate.module#LeadcreationcpkmupdateModule'
},
{
  path: 'approval/cpkm-process',
  loadChildren: './views/approval/cpkm-process/cpkm-process.module#CpkmProcessModule'
}, 
{
path: 'pay-payment',
loadChildren: './views/pay-payment/pay-payment.module#PayPaymentModule'
},
{
  path: 'approval/jobcard',
  loadChildren: './views/approval/jobcard/jobcard.module#JobcardModule'
},
{
  path: 'approval/payment',
  loadChildren: './views/approval/payment/payment.module#PaymentModule'
},
{
  path: 'approval/expenses',
  loadChildren: './views/approval/expenses/expenses.module#ExpensesModule'
},
{
  path: 'approval/ledger',
  loadChildren: './views/approval/ledger/ledger.module#LedgerModule'
},
{
  path: 'approval/fitter',
  loadChildren: './views/approval/fitter/fitter.module#FitterModule'
},
{
  path: 'approval/payment-group',
  loadChildren: './views/approval/payment-group/payment-group.module#PaymentgroupModule'
},
{
path: 'approval/transfer-request-approval',
loadChildren: './views/approval/transfer-request-approval/transfer-request-approval.module#TransferrequestapprovalModule'
},
{
  path: 'approval/tyre-sold-approval',
  loadChildren: './views/approval/tyre-sold-approval/tyre-sold-approval.module#TyresoldapprovalModule'
  },
{
  path: 'promotion',
  loadChildren: './views/promotion/promotion.module#PromotionModule'
  },
  {
  path: 'promotion/create',
  loadChildren: './views/promotion/create/promotion-create.module#PromotionCreateModule'
  },
  {
  path: 'promotion/edit',
  loadChildren: './views/promotion/edit/promotion-edit.module#PromotionEditModule'
  },
  {
  path: 'promotion/view',
  loadChildren: './views/promotion/view/promotion-view.module#PromotionViewModule'
  },
  {
    path: 'checkout',
    loadChildren: './views/checkout/checkout.module#CheckoutModule'
    },
    {
    path: 'checkout/create',
    loadChildren: './views/checkout/create/checkout-create.module#CheckoutCreateModule'
    },
   
  
  {
path: 'customer-price-mapping',
loadChildren: './views/customer-price-mapping/customer-price-mapping.module#CustomerpriceMappingModule'
},
{
path: 'customer-price-mapping/create',
loadChildren: './views/customer-price-mapping/create/customer-price-mapping-create.module#CustomerpriceMappingCreateModule'
},
{
path: 'customer-price-mapping/edit',
loadChildren: './views/customer-price-mapping/edit/customer-price-mapping-edit.module#CustomerpriceMappingEditModule'
},

  {
path: 'request-closing-daywise',
loadChildren: './views/request-closing-daywise/request-closing-daywise.module#RequestClosingDaywiseModule'
},
{
  path: 'rfid-dashboard',
  loadChildren: './views/rfid-dashboard/rfid-dashboard.module#RfidDashboardModule'
  },
  {
path: 'request-demo/create',
loadChildren: './views/request-demo/create/request-demo-create.module#RequestDemoCreateModule'
},
{
path: 'office-payment',
loadChildren: './views/office-payment/office-payment.module#OfficePaymentModule'
},
{
path: 'office-payment/create',
loadChildren: './views/office-payment/create/office-payment-create.module#OfficePaymentCreateModule'
},
{
path: 'office-payment/view',
loadChildren: './views/office-payment/view/office-payment-view.module#OfficePaymentViewModule'
},
{
  path: 'office-payment/edit',
  loadChildren: './views/office-payment/edit/office-payment-edit.module#OfficePaymentEditModule'
},
{
  path: 'office-expenses',
  loadChildren: './views/office-expenses/office-expenses.module#OfficeExpensesModule'
  },
  {
  path: 'office-expenses/create',
  loadChildren: './views/office-expenses/create/office-expenses-create.module#OfficeExpensesCreateModule'
  },
  {
  path: 'office-expenses/edit',
  loadChildren: './views/office-expenses/edit/office-expenses-edit.module#OfficeExpensesEditModule'
  },
  {
  path: 'office-expenses/view',
  loadChildren: './views/office-expenses/view/office-expenses-view.module#OfficeExpensesViewModule'
  },
  {
    path: 'ledger-statement',
    loadChildren: './views/ledger-statement/ledger-statement.module#LedgerStatementModule'
    },
  {
path: 'request-closing-daywise/view',
loadChildren: './views/request-closing-daywise/view/request-closing-daywise-view.module#RequestClosingDaywiseViewModule'
},
{
  path: 'ta-dependent-update',
  loadChildren: './views/ta-dependent-update/ta-dependent-update.module#TadependentupdateModule'
  },
{
  path: 'ta-update',
  loadChildren: './views/ta-update/ta-update.module#TaupdateModule'
  },
  {
    path: 'ta-update/create',
    loadChildren: './views/ta-update/create/ta-update-create.module#TaupdateCreateModule'
    },
    {
      path: 'ta-update/edit',
      loadChildren: './views/ta-update/edit/ta-update-edit.module#TaupdateEditModule'
  },
    {
      path: 'ta-update/view',
      loadChildren: './views/ta-update/view/ta-update-view.module#TaupdateViewModule'
  },
  /* {
    path: 'ta-update/edit',
    loadChildren: './views/ta-update/edit/ta-update-edit.module#TaupdateEditModule'
}, */
  {
    path: 'approval/ta-list',
    loadChildren: './views/approval/ta-list/ta-list.module#TAListModule'
  },
  {
    path: 'approval/ta-group',
    loadChildren: './views/approval/ta-group/ta-group.module#TAGroupModule'
  },
  {
    path: 'approval/fitter-salary',
    loadChildren: './views/approval/fitter-salary/fitter-salary.module#FitterSalaryModule'
  },
  {
    path: 'approval/common-task',
    loadChildren: './views/approval/task/task.module#TaskModule'
  },
  {
    path: 'ta-report',
    loadChildren: './views/ta-report/ta-report.module#TAReportModule'
  },
  {
  path: 'ticket-issue-master',
  loadChildren: './views/ticket-issues-master/ticket-issues-master.module#TicketissuemasterModule'
  },
  {
  path: 'ticket-issue-master/create',
  loadChildren: './views/ticket-issues-master/create/ticket-issues-master-create.module#TicketissuemasterCreateModule'
  },
  {
  path: 'ticket-issue-master/edit',
  loadChildren: './views/ticket-issues-master/edit/ticket-issues-master-edit.module#TicketissuemasterEditModule'
  },
  {
    path: 'ticket-department-user-mapping',
    loadChildren: './views/ticket-department-user-mapping/ticket-department-user-mapping.module#TicketDepartmentUsermappingModule'
    },
    {
    path: 'ticket-department-user-mapping/edit',
    loadChildren: './views/ticket-department-user-mapping/edit/ticket-department-user-mapping-edit.module#TicketDepartmentUsermappingEditModule'
    },
    {
      path: 'ticket-master',
      loadChildren: './views/ticket-master/ticket-master.module#TicketmasterModule'
      },
      {
        path: 'ticket-assigned',
        loadChildren: './views/ticket-assigned/ticket-assigned.module#TicketassignedModule'
        },
      {
      path: 'ticket-master/create',
      loadChildren: './views/ticket-master/create/ticket-master-create.module#TicketmasterCreateModule'
      },
      {
      path: 'ticket-master/edit',
      loadChildren: './views/ticket-master/edit/ticket-master-edit.module#TicketmasterEditModule'
      },
{
  path: 'fleecacenter-executive',
  loadChildren: './views/fleecacenter-executive/fleecacenter-executive.module#FleecacenterExecutiveModule'
},
{
  path: 'center-error-report',
  loadChildren: './views/center-error-report/center-error-report.module#CenterErrorReportModule'
},
{
  path: 'center-customer-list',
  loadChildren: './views/center-customer-list/center-customer-list.module#CenterCustomerListModule'
},

{
  path: 'work-activity',
  loadChildren: './views/work-activity/work-activity.module#WorkactivityModule'
},
{
  path: 'fitter-activity',
  loadChildren: './views/fitter-activity/fitter-activity.module#FitteractivityModule'
},
{
  path: 'cpkm-lead',
  loadChildren: './views/cpkm-lead/cpkm-lead.module#CpkmleadModule'
},

{
  path: 'cpkm-lead/view',
  loadChildren: './views/cpkm-lead/view/cpkm-lead-view.module#CpkmleadViewModule'
},

{
  path: 'cpkm-lead/cpkmupdate',
  loadChildren: './views/cpkm-lead/cpkmupdate/cpkm-lead-cpkmupdate.module#CpkmleadcpkmupdateModule'
  },
  
{
path: 'job-card/client-view',
loadChildren: './views/job-card/client-view/job-card-client-view.module#JobcardClientViewModule'
},
{
  path: 'tyre-mileage',
  loadChildren: './views/tyre-mileage/tyre-mileage.module#TyremileageModule'
  },
  {
path: 'stage-wise-request',
loadChildren: './views/stage-wise-request/stage-wise-request.module#StageWiseRequestModule'
},
{
  path: 'ta-group',
  loadChildren: './views/ta-group/ta-group.module#TAGroupListModule'
},
{
path: 'cpkm-lead-crm',
loadChildren: './views/cpkm-lead-crm/cpkm-lead-crm.module#CpkmleadCrmModule'
},
{
path: 'cpkm-lead-crm/cpkm-lead-approve',
loadChildren: './views/cpkm-lead-crm/cpkm-lead-approve/cpkm-lead-approve.module#CpkmLeadApproveModule'
},
{
path: 'cpkm-lead/postapproval',
loadChildren: './views/cpkm-lead/postapproval/cpkm-lead-postapproval.module#CpkmleadPostapprovalModule'
},
{
  path: 'en-route-crm',
  loadChildren: './views/en-route-crm/en-route-crm.module#EnRouteCrmModule'
},
 {
   path: 'tyre-wise-jobcard',
   loadChildren: './views/tyre-wise-jobcard/tyre-wise-jobcard.module#TyrewisejobcardModule'
    },
{
  path: 'customer-tyre-dashboard',
  loadChildren: './views/customer-tyre-dashboard/customer-tyre-dashboard.module#CustomertyredashboardModule',
  data: {title: 'Customer Tyre Dashboard'}
},
{
  path: 'approval/material-conversion',
  loadChildren: './views/approval/production-conversion/production-conversion.module#ProductionconversionModule'
},
{
  path: 'report-demo',
  loadChildren: './views/report-demo/report-demo.module#ReportDemoModule'
},
{
  path: 'new-customer-price-mapping',
  loadChildren: './views/new-customer-price-mapping/new-customer-price-mapping.module#NewCustomerpriceMappingModule'
},
{
  path: 'new-customer-price-mapping/create',
  loadChildren: './views/new-customer-price-mapping/create/new-customer-price-mapping-create.module#NewCustomerpriceMappingCreateModule'
},
{
  path: 'new-customer-price-mapping/edit',
  loadChildren: './views/new-customer-price-mapping/edit/new-customer-price-mapping-edit.module#NewCustomerpriceMappingEditModule'
  },
  {
    path: 'approval/advance-approval',
    loadChildren: './views/approval/advance-approval/advance-approval.module#AdvanceapprovalModule'
  },
  {
    path: 'advance-payment',
    loadChildren: './views/advance-payment/advance-payment.module#AdvancepaymentModule'
  },
  {
    path: 'advance-payment/create',
    loadChildren: './views/advance-payment/create/advance-payment-create.module#AdvancepaymentCreateModule'
  },
  {
    path: 'advance-payment/edit',
    loadChildren: './views/advance-payment/edit/advance-payment-edit.module#AdvancepaymentEditModule'
  },
  {
    path: 'advance-payment-report',
    loadChildren: './views/advance-payment-report/advance-payment-report.module#AdvancepaymentReportModule'
  },
  {
    path: 'approval/project-budget-approval',
    loadChildren: './views/approval/project-budget/project-budget-approval.module#ProjectbudgetapprovalModule'
  },
  {
    path: 'approval/project-expenses-approval',
    loadChildren: './views/approval/project-expenses/project-expenses-approval.module#ProjectexpensesapprovalModule'
  },

  {
    path: 'dealer',
    loadChildren: './views/dealer/dealer.module#DealerModule'
    },
    {
    path: 'dealer/create',
    loadChildren: './views/dealer/create/dealer-create.module#DealerCreateModule'
    },
    {
    path: 'dealer/edit',
    loadChildren: './views/dealer/edit/dealer-edit.module#DealerEditModule'
    },
    {
    path: 'dealer/view',
    loadChildren: './views/dealer/view/dealer-view.module#DealerViewModule'
    },

    {
    path: 'tyre-buy',
    loadChildren: './views/tyre-buy/tyrebuy.module#TyrebuyModule'
    },
    {
    path: 'tyre-buy/create',
    loadChildren: './views/tyre-buy/create/tyrebuy-create.module#TyrebuyCreateModule'
    },
    {
    path: 'tyre-buy/edit',
    loadChildren: './views/tyre-buy/edit/tyrebuy-edit.module#TyrebuyEditModule'
    },
    {
    path: 'tyre-buy/view',
    loadChildren: './views/tyre-buy/view/tyrebuy-view.module#TyrebuyViewModule'
    },      
    {
    path: 'tyre-buy/challan',
    loadChildren: './views/tyre-buy/challan/tyrebuy-challan.module#TyrebuyChallanModule'
    },
    {
      path: 'expenses-upload-project',
      loadChildren: './views/expenses-upload-project/expenses-upload-project.module#ExpensesUploadProjectModule'
    },
    {
      path: 'claim-order',
      loadChildren: './views/claim-order/claim-order.module#ClaimOrderModule'
    }, 
    {
    path: 'claim-order/create',
    loadChildren: './views/claim-order/create/claim-order-create.module#ClaimOrderCreateModule'
    },
    {
      path: 'claim-orders/view',
      loadChildren: './views/claim-order/view/claim-order-view.module#ClaimOrderViewModule'
      },
      {
        path: 'claim-orders/receive',
        loadChildren: './views/claim-order/receive/claim-order-receive.module#ClaimOrderReceiveModule'
        },
        {
    path: 'closing-stock-report',
    loadChildren: './views/closing-stock-report/closing-stock-report.module#ClosingStockReportModule'
    },
    {
path: 'zone-mapping',
loadChildren: './views/zone-mapping/zone-mapping.module#ZonemappingModule'
},
{
path: 'zone-mapping/create',
loadChildren: './views/zone-mapping/create/zone-mapping-create.module#ZonemappingCreateModule'
},
{
path: 'zone-mapping/edit',
loadChildren: './views/zone-mapping/edit/zone-mapping-edit.module#ZonemappingEditModule'
},
{
path: 'route-master',
loadChildren: './views/route-master/routemaster.module#RoutemasterModule'
},
{
path: 'route-master/create',
loadChildren: './views/route-master/create/routemaster-create.module#RoutemasterCreateModule'
},
{
path: 'route-master/edit',
loadChildren: './views/route-master/edit/routemaster-edit.module#RoutemasterEditModule'
},
{
path: 'vendor',
loadChildren: './views/vendor/vendor.module#VendorModule'
},
{
path: 'vendor/vendorview',
loadChildren: './views/vendor/vendorview/vendor-vendorview.module#VendorVendorviewModule',
data: {title: 'Vendor View'} 
},
{
path: 'vendor/vendor',
loadChildren: './views/vendor/vendor/vendor-vendor.module#VendorVendorModule',
data: {title: 'Vendor Status'} 
},
{
path: 'vendor/challan',
loadChildren: './views/vendor/challan/vendor-challan.module#VendorChallanModule'
},
{
path: 'vendor/challanview',
loadChildren: './views/vendor/challanview/vendor-challanview.module#VendorChallanviewModule'
},
{
path: 'tyre-sale',
loadChildren: './views/tyre-sale/tyre-sale.module#TyresaleModule'
},
{
path: 'tyre-sale/create',
loadChildren: './views/tyre-sale/create/tyre-sale-create.module#TyresaleCreateModule'
},
{
path: 'tyre-sale/edit',
loadChildren: './views/tyre-sale/edit/tyre-sale-edit.module#TyresaleEditModule'
},
{
path: 'tyre-sale/view',
loadChildren: './views/tyre-sale/view/tyre-sale-view.module#TyresaleViewModule'
},
{
path: 'tyresale-invoice-pending',
loadChildren: './views/tyresale-invoice-pending/tyresale-invoice-pending.module#TyresaleInvoicePendingModule'
},
{
path: 'tyre-sale/request-edit',
loadChildren: './views/tyre-sale/request-edit/tyre-sale-request-edit.module#TyresaleRequestEditModule'
},
{
path: 'cpkm-calculation',
loadChildren: './views/cpkm-calculation/cpkm-calculation.module#CpkmcalculationModule'
},
{
path: 'cpkm-calculation/create',
loadChildren: './views/cpkm-calculation/create/cpkm-calculation-create.module#CpkmcalculationCreateModule'
},
{
  path: 'closing-stock-add',
  loadChildren: './views/project-management/closing-add/closing-add.module#ClosingAddModule'
},
{
  path: 'stock-gatein',
  loadChildren: './views/stock-gatein/stock-gatein.module#StockGetInModule'
},
{
  path: 'stock-gatein/view',
  loadChildren: './views/stock-gatein/view/stock-gatein-view.module#StockGetInViewModule'
},
{
  path: 'stock-gatein/receive',
  loadChildren: './views/stock-gatein/receive/stock-gatein-receive.module#StockGetInReceiveModule'
},
{
path: 'in-out-register',
loadChildren: './views/in-out-register/in-out-register.module#InOutRegisterModule'
},
{
path: 'in-out-register/create',
loadChildren: './views/in-out-register/create/in-out-register-create.module#InOutRegisterCreateModule'
},
{
path: 'in-out-register/view',
loadChildren: './views/in-out-register/view/in-out-register-view.module#InOutRegisterViewModule'
},
{
path: 'tyre-sold',
loadChildren: './views/tyre-sold/tyre-sold.module#TyresoldModule'
},
{
path: 'tyre-sold/create',
loadChildren: './views/tyre-sold/create/tyre-sold-create.module#TyresoldCreateModule'
},

{
path: 'tyre-sold/view',
loadChildren: './views/tyre-sold/view/tyre-sold-view.module#TyresoldViewModule'
},
{
path: 'service-engg-transfer',
loadChildren: './views/service-engg-transfer/service-engg-transfer.module#ServiceenggtransferModule'
},
{
path: 'service-engg-transfer/create',
loadChildren: './views/service-engg-transfer/create/service-engg-transfer-create.module#ServiceEnggTransferModule'
},
{
path: 'service-engg-transfer/view',
loadChildren: './views/service-engg-transfer/view/service-engg-transfer-view.module#ServiceEnggTransferModule'
},
{
path: 'service-engg-transfer/edit',
loadChildren: './views/service-engg-transfer/edit/service-engg-transfer-edit.module#ServiceEnggTransferModule'
},
{
path: 'quotation',
loadChildren: './views/quotation/quotation.module#QuotationModule'
},
{
path: 'quotation/create',
loadChildren: './views/quotation/create/quotation-create.module#QuotationCreateModule'
},
{
path: 'quotation/edit',
loadChildren: './views/quotation/edit/quotation-edit.module#QuotationEditModule'
},
{
path: 'approval/quotation-approval',
loadChildren: './views/approval/quotation-approval/quotation-approval.module#QuotationapprovalModule'
},
{
path: 'profit-loss-report',
loadChildren: './views/profit-loss-report/profit-loss-report.module#ProfitLossReportModule'
},
{
path: 'profit-loss-zonewise-report',
loadChildren: './views/profit-loss-zonewise-report/profit-loss-zonewise-report.module#ProfitLossZonewiseReportModule'
},
{
path: 'insentive',
loadChildren: './views/insentive/insentive.module#InsentiveModule'
},
{
path: 'insentive/create',
loadChildren: './views/insentive/create/insentive-create.module#InsentiveCreateModule'
},
{
path: 'site-permission',
loadChildren: './views/site-permission/site-permission.module#SitePermissionModule'
},
{
path: 'site-permission/create',
loadChildren: './views/site-permission/create/site-permission-create.module#SitePermissionCreateModule'
},
{
path: 'site-permission/view',
loadChildren: './views/site-permission/view/site-permission-view.module#SitePermissionViewModule'
},
{
path: 'site-permission/approve',
loadChildren: './views/site-permission/approve/site-permission-approve.module#SitePermissionApproveModule'
},
{
path: 'whatsapp-msg-mapping',
loadChildren: './views/whatsapp-msg-mapping/whatsapp-msg-mapping.module#WhatsappmsgmappingModule'
},
{
path: 'whatsapp-msg-mapping/create',
loadChildren: './views/whatsapp-msg-mapping/create/whatsapp-msg-mapping-create.module#WhatsappmsgmappingCreateModule'
}, 
{
path: 'whatsapp-msg-mapping/edit',
loadChildren: './views/whatsapp-msg-mapping/edit/whatsapp-msg-mapping-edit.module#WhatsappmsgmappingEditModule'
},
{
path: 'credit-note',
loadChildren: './views/credit-note/credit-note.module#CreditNoteModule'
},
{
path: 'credit-note/create',
loadChildren: './views/credit-note/create/credit-note-create.module#CreditNoteCreateModule'
},
{
path: 'online-payment-configuration',
loadChildren: './views/fleeca-center-invoice/online-payment-configuration/online-payment-configuration.module#OnlinePaymentConfigurationModule'
},
{
path: 'online-payment-configuration-index',
loadChildren: './views/fleeca-center-invoice/online-payment-configuration-index/online-payment-configuration-index.module#OnlinePaymentConfigurationIndexModule'
},
{
path: 'fleeca-center-crm/view',
loadChildren: './views/fleeca-center-crm/view/fleeca-center-crm-view.module#FleecaCenterCrmViewModule'
},
{
path: 'fleeca-center-crm-old',
loadChildren: './views/fleeca-center-crm-old/fleeca-center-crm-old.module#FleecaCenterCrmOldModule'
},
{
path: 'online-payment-transaction-logs',
loadChildren: './views/online-payment-transaction-logs/online-payment-transaction-logs.module#OnlinePaymentTransactionLogModule'
},
{
path: 'image-view',
loadChildren: './views/image-view/image-view.module#ImageViewModule'
},
{
path: 'client-psi-mapping/create',
loadChildren: './views/client-psi-mapping/create/client-psi-mapping-create.module#ClientPsiMappingCreateModule'
},
{
path: 'client-psi-mapping',
loadChildren: './views/client-psi-mapping/client-psi-mapping.module#ClientPsiMappingModule'
},
{
path: 'client-psi-mapping/edit',
loadChildren: './views/client-psi-mapping/edit/client-psi-mapping-edit.module#ClientPsiMappingEditModule'
},
{
  path: 'fuel-requisition',
  loadChildren: './views/fleet-management/fuel-requisition/fuel-requisition.module#FuelRequisitionModule'
},
{
  path: 'fuel-requisition/create',
  loadChildren: './views/fleet-management/fuel-requisition/create/fuel-requisition-create.module#FuelRequisitionCreateModule'
},
{
  path: 'fuel-requisition/edit',
  loadChildren: './views/fleet-management/fuel-requisition/edit/fuel-requisition-edit.module#FuelRequisitionEditModule'
},
{
  path: 'fuel-requisition/view',
  loadChildren: './views/fleet-management/fuel-requisition/view/fuel-requisition-view.module#FuelRequisitionViewModule'
},
{
  path: 'fuel-requisition/history',
  loadChildren: './views/fleet-management/fuel-requisition/history/fuel-requisition-history.module#FuelRequisitionHistoryModule'
},
{
  path: 'trip',
  loadChildren: './views/fleet-management/trip/trip.module#TripModule'
},
{
  path: 'trip/create',
  loadChildren: './views/fleet-management/trip/create/trip-create.module#TripCreateModule'
},
{
  path: 'trip/edit',
  loadChildren: './views/fleet-management/trip/edit/trip-edit.module#TripEditModule'
},
{
path: 'fuel-station',
loadChildren: './views/fuel-station/fuel-station.module#FuelStationModule'
},
{
path: 'fuel-station/create',
loadChildren: './views/fuel-station/create/fuel-station-create.module#FuelStationCreateModule'
},
{
path: 'fuel-station/edit',
loadChildren: './views/fuel-station/edit/fuel-station-edit.module#FuelStationEditModule'
},
{
  path: 'maintainence-expenses',
  loadChildren: './views/fleet-management/maintainence-expenses/maintainence-expenses.module#MaintainenceExpensesModule'
},
{
  path: 'maintainence-expenses/create',
  loadChildren: './views/fleet-management/maintainence-expenses/create/maintainence-expenses-create.module#MaintainenceExpensesCreateModule'
},
{
  path: 'maintainence-expenses/edit',
  loadChildren: './views/fleet-management/maintainence-expenses/edit/maintainence-expenses-edit.module#MaintainenceExpensesEditModule'
},
{
  path: 'maintainence-expenses/view',
  loadChildren: './views/fleet-management/maintainence-expenses/view/maintainence-expenses-view.module#MaintainenceExpensesViewModule'
},
{
path: 'sms-config',
loadChildren: './views/sms-config/sms-config.module#SMSconfigModule'
},
{
path: 'tyre-history-report',
loadChildren: './views/tyre-history/tyre-history.module#TyreHistoryModule'
}
]
}
];

@NgModule({
imports: [ RouterModule.forRoot(routes) ],
exports: [ RouterModule ]
})
export class AppRoutingModule {}
