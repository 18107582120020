import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from  '@angular/common/http';
import { Router, NavigationEnd } from '@angular/router';
import { $ } from 'protractor';
import { Title } from '@angular/platform-browser';
@Injectable({
  providedIn: 'root'
})
@Component({
  selector: "org-dropdown",
  template: "FLEECA"
})
export class APIService {
//jsondata: string;
public  token:  any;
model :any;
id :any;
//Live Api Url
//API_URL  =  'https://app.fleeca.in/fleecatest/api';
API_URL  =  'https://lms.fleeca.in/fleecatesting/api';
 //API_URL  =  'http://192.168.2.30/fleeca/api';
//Demo Api Url
//API_URL  =  'http://demo.fleeca.in/fleecatesting/api';
API_URLS  =  'https://ai.fleeca.in/api';
API_URLS_ML  =  'https://ml.fleeca.in/api';
Test_API_URL = 'http://demo.fleeca.in/fleecatesting/api';
table :any;
status_id :any;
index : any;
loading:any;
display:any;
opacity:any;
lists:any;
url='tyre-dashboard';
dashboard='tyre-dashboard';
check_tb_status:any;
title = 'angulartitle';
 //options = {headers: {'Content-Type': 'application/x-www-form-urlencoded'}}; 
constructor(private  http:  HttpClient, private  router:  Router, private titleService: Title) {
	this.dashboard = localStorage.getItem("dashboard");
	this.token = localStorage.getItem("auth_token");
	if(this.token==undefined){
		//localStorage.setItem('auth_token',null);
		this.router.navigate(['login']);
	}
}

getToken(token) {
	//alert(token);
      this.token = token;
	 // alert(this.token);
    }
 
sendToken() {
  return this.token;
}

getApiUrl(url, jsondata){
	//alert(this.token);
this.token = localStorage.getItem("auth_token");	
const httpOptions = {
	headers: new HttpHeaders({
	'Content-Type': 'application/json',
	'Access-Control-Allow-Origin': '*',
	'Authorization': 'Bearer '+this.token
	})
};
this.title = this.titleService.getTitle();
    return this.http.post(`${this.API_URL}/`+url, jsondata, httpOptions);
}

getTestApiUrl(url, jsondata){
	//alert(this.token);
this.token = localStorage.getItem("auth_token");	
const httpOptions = {
	headers: new HttpHeaders({
	'Content-Type': 'application/json',
	'Access-Control-Allow-Origin': '*',
	'Authorization': 'Bearer '+this.token
	})
};
    return this.http.post(`${this.Test_API_URL}/`+url, jsondata, httpOptions);
}

getOherApiUrl(url, jsondata){
	//alert(this.token);
this.token = localStorage.getItem("auth_token");	
const httpOptions = {
	headers: new HttpHeaders({
	'Content-Type': 'multipart/form-data',
	'Access-Control-Allow-Origin': '*',
	'Authorization': 'Bearer '+this.token
	})
};
    return this.http.post(`${this.API_URL}/`+url, jsondata, httpOptions);
}

getDjangoApiUrl(url, jsondata){
	//alert(url);alert(JSON.stringify(jsondata));
	this.token = localStorage.getItem("auth_token");
	const httpOptions = {
	headers: new HttpHeaders({
	"Content-Type": "application/json",
	'Access-Control-Allow-Origin': '*',
	})
	}; 
	
	return this.http.post(`${this.API_URLS}/`+url,jsondata,httpOptions);
	}

	getDjangoMLApiUrl(url, jsondata){
		//alert(url);alert(JSON.stringify(jsondata));
		this.token = localStorage.getItem("auth_token");
		const httpOptions = {
		headers: new HttpHeaders({
		"Content-Type": "application/json",
		'Access-Control-Allow-Origin': '*',
		})
		}; 
		
		return this.http.post(`${this.API_URLS_ML}/`+url,jsondata,httpOptions);
		}


getApiPermission(){
	this.router.navigate(['404']);
}
status(status_id, table, index){
	if(confirm("Are you sure you want to change status?")){
	this.loading = true;
				var jsondata  = JSON.stringify({'status_id':status_id, 'table':table});					
				this.token = localStorage.getItem("auth_token");		
				const httpOptions = {
					headers: new HttpHeaders({
					'Content-Type': 'application/json',
					'Access-Control-Allow-Origin': '*',
					'Authorization': 'Bearer '+this.token
					})
				};
				
				
				this.http.post(`${this.API_URL}/pagename/status`, jsondata, httpOptions).subscribe((data:  any) => {
					//alert(JSON.stringify(data.msg));					
					if(data.tb_status==1){
						document.getElementById('lbl'+index).innerHTML = 'Active';
						var element = document.getElementById("lbl"+index);
						element.classList.remove("badge-danger");
						element.classList.add("badge-success");
					}else{
						document.getElementById('lbl'+index).innerHTML = 'In-Active';
						var element = document.getElementById("lbl"+index);
						element.classList.remove("badge-success");
						element.classList.add("badge-danger");
					}
					this.loading = false;
					});
			}
}


trailerstatus(status_id, table, index){
				this.loading = true;
				var jsondata  = JSON.stringify({'status_id':status_id, 'table':table});					
				this.token = localStorage.getItem("auth_token");		
				const httpOptions = {
					headers: new HttpHeaders({
					'Content-Type': 'application/json',
					'Access-Control-Allow-Origin': '*',
					'Authorization': 'Bearer '+this.token
					})
				};			
				
				this.http.post(`${this.API_URL}/pagename/status`, jsondata, httpOptions).subscribe((data:  any) => {
					//alert(JSON.stringify(data.msg));					
					if(data.tb_status==1){
						this.check_tb_status = 1;
						document.getElementById('lbl'+index).innerHTML = 'Active';
						var element = document.getElementById("lbl"+index);
						element.classList.remove("badge-danger");
						element.classList.add("badge-success");
					}else{
						this.check_tb_status = 0;
						document.getElementById('lbl'+index).innerHTML = 'In-Active';
						var element = document.getElementById("lbl"+index);
						element.classList.remove("badge-success");
						element.classList.add("badge-danger");
					}					
					this.loading = false;
					});
					
}

lotstatus(status_id, table, index){
	this.loading = true;
				var jsondata  = JSON.stringify({'status_id':status_id, 'table':table});					
				this.token = localStorage.getItem("auth_token");		
				const httpOptions = {
					headers: new HttpHeaders({
					'Content-Type': 'application/json',
					'Access-Control-Allow-Origin': '*',
					'Authorization': 'Bearer '+this.token
					})
				};
				
				
				this.http.post(`${this.API_URL}/pagename/lotstatus`, jsondata, httpOptions).subscribe((data:  any) => {
					//alert(JSON.stringify(data.msg));					
					if(data.tb_status==1){
						document.getElementById('lbl'+index).innerHTML = 'Active';
						var element = document.getElementById("lbl"+index);
						element.classList.remove("badge-danger");
						element.classList.add("badge-success");
					}else{
						document.getElementById('lbl'+index).innerHTML = 'In-Active';
						var element = document.getElementById("lbl"+index);
						element.classList.remove("badge-success");
						element.classList.add("badge-danger");
					}
					this.loading = false;
					});
}

vehiclestatus(status_id, table, index){
	this.loading = true;
				var jsondata  = JSON.stringify({'status_id':status_id, 'table':table});					
				this.token = localStorage.getItem("auth_token");		
				const httpOptions = {
					headers: new HttpHeaders({
					'Content-Type': 'application/json',
					'Access-Control-Allow-Origin': '*',
					'Authorization': 'Bearer '+this.token
					})
				};
				this.http.post(`${this.API_URL}/pagename/vehiclestatus`, jsondata, httpOptions).subscribe((data:  any) => {
					//alert(JSON.stringify(data.msg));					
					if(data.tb_status==0){
						document.getElementById('lbl'+index).innerHTML = 'In-Active';
						var element = document.getElementById("lbl"+index);
						element.classList.remove("badge-warning");
						element.classList.add("badge-danger");
					}
					else if(data.tb_status==1){
						document.getElementById('lbl'+index).innerHTML = 'MOU';
						var element = document.getElementById("lbl"+index);
						element.classList.remove("badge-danger");
						element.classList.add("badge-success");
					}else{
						document.getElementById('lbl'+index).innerHTML = 'CPKM';
						var element = document.getElementById("lbl"+index);
						element.classList.remove("badge-success");
						element.classList.add("badge-warning");
					}
					this.loading = false;
					});
}


postatus(status_id, table, index){
	this.loading = true;
				var jsondata  = JSON.stringify({'status_id':status_id, 'table':table});					
				this.token = localStorage.getItem("auth_token");		
				const httpOptions = {
					headers: new HttpHeaders({
					'Content-Type': 'application/json',
					'Access-Control-Allow-Origin': '*',
					'Authorization': 'Bearer '+this.token
					})
				};
				this.http.post(`${this.API_URL}/pagename/purchaseorderstatus`, jsondata, httpOptions).subscribe((data:  any) => {
					if(data.tb_status==3){
						document.getElementById('lbl'+index).innerHTML = 'Cancelled';
						var element = document.getElementById("lbl"+index);
						element.classList.remove("badge-warning");
						element.classList.add("badge-danger");
					}
					this.loading = false;
					});
}

statusc(status_id, table, index){
	this.loading = true;
				var jsondata  = JSON.stringify({'status_id':status_id, 'table':table});					
				this.token = localStorage.getItem("auth_token");		
				const httpOptions = {
					headers: new HttpHeaders({
					'Content-Type': 'application/json',
					'Access-Control-Allow-Origin': '*',
					'Authorization': 'Bearer '+this.token
					})
				};
				
				
				this.http.post(`${this.API_URL}/pagename/status`, jsondata, httpOptions).subscribe((data:  any) => {
					//alert(JSON.stringify(data.msg));					
					if(data.tb_status==1){
						document.getElementById('lblc'+index).innerHTML = 'Active';
						var element = document.getElementById("lblc"+index);
						element.classList.remove("badge-danger");
						element.classList.add("badge-success");
					}else{
						document.getElementById('lblc'+index).innerHTML = 'In-Active';
						var element = document.getElementById("lblc"+index);
						element.classList.remove("badge-success");
						element.classList.add("badge-danger");	
					}
					this.loading = false;
					});
}
statuscp(status_id, table, index){
	this.loading = true;
				var jsondata  = JSON.stringify({'status_id':status_id, 'table':table});					
				this.token = localStorage.getItem("auth_token");		
				const httpOptions = {
					headers: new HttpHeaders({
					'Content-Type': 'application/json',
					'Access-Control-Allow-Origin': '*',
					'Authorization': 'Bearer '+this.token
					})
				};
				
				
				this.http.post(`${this.API_URL}/pagename/status`, jsondata, httpOptions).subscribe((data:  any) => {
					//alert(JSON.stringify(data.msg));					
					if(data.tb_status==1){
						document.getElementById('lblcp'+index).innerHTML = 'Active';
						var element = document.getElementById("lblcp"+index);
						element.classList.remove("badge-danger");
						element.classList.add("badge-success");
					}else{
						document.getElementById('lblcp'+index).innerHTML = 'In-Active';
						var element = document.getElementById("lblcp"+index);
						element.classList.remove("badge-success");
						element.classList.add("badge-danger");	
					}
					this.loading = false;
					});
}

batchstatus(status_id, table, index){
	this.loading = true;
				var jsondata  = JSON.stringify({'status_id':status_id, 'table':table});					
				this.token = localStorage.getItem("auth_token");		
				const httpOptions = {
					headers: new HttpHeaders({
					'Content-Type': 'application/json',
					'Access-Control-Allow-Origin': '*',
					'Authorization': 'Bearer '+this.token
					})
				};
				
				
				this.http.post(`${this.API_URL}/pagename/batchstatus`, jsondata, httpOptions).subscribe((data:  any) => {
					//alert(JSON.stringify(data.msg));					
					if(data.tb_status==1){
						document.getElementById('lbl'+index).innerHTML = 'Open';
						var element = document.getElementById("lbl"+index);
						element.classList.remove("badge-danger");
						element.classList.add("badge-success");
					}else{
						document.getElementById('lbl'+index).innerHTML = 'Closed';
						var element = document.getElementById("lbl"+index);
						element.classList.remove("badge-success");
						element.classList.add("badge-danger");	
					}
					this.loading = false;
					});
}


mappinghistorystatus(status_id,user_id,firm,office, table, index){
	this.loading = true;
				var jsondata  = JSON.stringify({'status_id':status_id,'user_id':user_id,'firm':firm,'office':office, 'table':table});					
				this.token = localStorage.getItem("auth_token");		
				const httpOptions = {
					headers: new HttpHeaders({
					'Content-Type': 'application/json',
					'Access-Control-Allow-Origin': '*',
					'Authorization': 'Bearer '+this.token
					})
				};
				
				
				this.http.post(`${this.API_URL}/pagename/mappinghistorystatus`, jsondata, httpOptions).subscribe((data:  any) => {
					//alert(JSON.stringify(data.msg));					
					if(data.tb_status==1){
						document.getElementById('lbl'+index).innerHTML = 'Active';
						var element = document.getElementById("lbl"+index);
						element.classList.remove("badge-danger");
						element.classList.add("badge-success");
					}else{
						document.getElementById('lbl'+index).innerHTML = 'In-Active';
						var element = document.getElementById("lbl"+index);
						element.classList.remove("badge-success");
						element.classList.add("badge-danger");	
					}
					this.loading = false;
					});
}


approve(id, approvestatus,index){
	this.loading = true;
			var jsondata  = JSON.stringify({'id':id, 'approvestatus':approvestatus});					
			this.token = localStorage.getItem("auth_token");		
			const httpOptions = {
				headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'Access-Control-Allow-Origin': '*',
				'Authorization': 'Bearer '+this.token
				})
			};			
			
			this.http.post(`${this.API_URL}/approval-user/status`, jsondata, httpOptions).subscribe((data:  any) => {
				//alert(JSON.stringify(data.msg));					
				if(data.tb_status==1){
					document.getElementById('lbl'+index).innerHTML = 'Active';
					var element = document.getElementById("lbl"+index);
					element.classList.remove("badge-danger");
					element.classList.add("badge-success");
				}else{
					document.getElementById('lbl'+index).innerHTML = 'In-Active';
					var element = document.getElementById("lbl"+index);
					element.classList.remove("badge-success");
					element.classList.add("badge-danger");	
				}
				this.loading = false;
				});
			}

}
