import { Component } from '@angular/core';
import {Validators, FormBuilder, FormGroup } from '@angular/forms';
@Component({
  selector: 'app-dashboard',
  templateUrl: 'register.component.html'
})
export class RegisterComponent {
public todos : FormGroup;
  constructor(public formBuilder: FormBuilder) {

this.todos  = this.formBuilder.group({
			user: ['',Validators.required],
			pass:['',Validators.required],
		});
		
		alert(JSON.stringify(this.todos.value));
	  }
logForm()
	{
		alert('sdfsdf');
		var kiloSabjiJSONdata = JSON.stringify(this.todos.value);
		alert(kiloSabjiJSONdata);
	}
}
